import _Decimal from 'decimal.js-light';
import JSBI from 'jsbi';
import toFormat from 'toformat';
import { Token, TokenType } from 'types';

const Decimal = toFormat(_Decimal);

const toSignificant = (
  currency: string,
  decimals: number,
  significantDigits: number = 6,
  format: object = { groupSeparator: '' }
): string => {
  const decimalScale = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals));
  const numerator = JSBI.BigInt(currency);
  const denominator = JSBI.BigInt(decimalScale);

  Decimal.set({
    precision: significantDigits + 1,
    rounding: Decimal.ROUND_DOWN,
  });
  const quotient = new Decimal(numerator.toString()).div(denominator.toString()).toSignificantDigits(significantDigits);
  return quotient.toFormat(quotient.decimalPlaces(), format);
};

export const toSignificantFromBigDecimal = (
  currency: string,
  significantDigits: number = 6,
  format: object = { groupSeparator: '' }
): string => {
  const quotient = new Decimal(currency).toSignificantDigits(significantDigits);
  return quotient.toFormat(quotient.decimalPlaces(), format);
};

export function formatCurrencyAmount(amount: bigint | undefined, token: Token, sigFigs: number = 6) {
  if (!amount) {
    return '-';
  }
  const decimalScale = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(token.decimals));

  if (JSBI.equal(JSBI.BigInt(amount.toString()), JSBI.BigInt(0))) {
    return '0';
  }

  if (new Decimal(amount.toString()).div(decimalScale.toString()).lessThan(new Decimal(1).div(100000))) {
    return '<0.00001';
  }

  return toSignificant(amount.toString(), token.decimals, sigFigs);
}

export const sortTokens = (tokenA: string, tokenB: string) => {
  let token0 = tokenA;
  let token1 = tokenB;

  if (tokenA > tokenB) {
    token0 = tokenB;
    token1 = tokenA;
  }

  return [token0, token1];
};

export const toToken: (overrides: {
  address?: string;
  type?: TokenType;
  decimals?: number;
  chainId?: number;
  symbol?: string;
  name?: string;
  underlyingTokens?: Token[];
  logoURI?: string;
}) => Token = ({ address, decimals, chainId, symbol, name, underlyingTokens, type, logoURI }) => ({
  decimals: decimals || 18,
  chainId: chainId || 1,
  address: address || '',
  name: name || '',
  symbol: symbol || '',
  type: type || TokenType.Base,
  underlyingTokens: underlyingTokens || [],
  logoURI,
});
