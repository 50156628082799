import { NETWORKS } from 'config/constants';
import { Token, TokenType } from 'types';

export const DAI_ADDRESSES = {
  [NETWORKS.mainnet.chainId]: '0x6b175474e89094c44da98b954eedeac495271d0f',
  [NETWORKS.optimism.chainId]: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  [NETWORKS.polygon.chainId]: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  [NETWORKS.arbitrum.chainId]: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
};

export const USDC_ADDRESSES = {
  [NETWORKS.mainnet.chainId]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  [NETWORKS.optimism.chainId]: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
  [NETWORKS.polygon.chainId]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  [NETWORKS.arbitrum.chainId]: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
};

const WETH_ADDRESSES = {
  [NETWORKS.mainnet.chainId]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [NETWORKS.ropsten.chainId]: '0xc778417e063141139fce010982780140aa0cd5ab',
  [NETWORKS.rinkeby.chainId]: '0xc778417e063141139fce010982780140aa0cd5ab',
  [NETWORKS.meanfinance.chainId]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [NETWORKS.goerli.chainId]: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
  [NETWORKS.kovan.chainId]: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
  [NETWORKS.optimismKovan.chainId]: '0x4200000000000000000000000000000000000006',
  [NETWORKS.optimism.chainId]: '0x4200000000000000000000000000000000000006',
  [NETWORKS.arbitrum.chainId]: '0x4200000000000000000000000000000000000006',
};

export const WETH = (chainId: number): Token => ({
  chainId,
  decimals: 18,
  address: WETH_ADDRESSES[chainId] || WETH_ADDRESSES[1],
  name: 'Wrapped Ether',
  symbol: 'WETH',
  logoURI:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  underlyingTokens: [],
  type: TokenType.Base,
});

export const PROTOCOL_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';

export const ETH = (chainId: number): Token => ({
  chainId,
  decimals: 18,
  address: PROTOCOL_TOKEN_ADDRESS,
  name: 'Ethereum',
  symbol: 'ETH',
  logoURI: 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1547036627',
  underlyingTokens: [],
  type: TokenType.Base,
});

export const MATIC = (chainId: number): Token => ({
  chainId,
  decimals: 18,
  address: PROTOCOL_TOKEN_ADDRESS,
  name: 'Matic',
  symbol: 'MATIC',
  logoURI:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png',
  underlyingTokens: [],
  type: TokenType.Base,
});

const WMATIC_ADDRESSES = {
  [NETWORKS.polygon.chainId]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  [NETWORKS.mumbai.chainId]: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
};

export const WMATIC = (chainId: number): Token => ({
  chainId,
  decimals: 18,
  address: WMATIC_ADDRESSES[chainId] || WMATIC_ADDRESSES[1],
  name: 'Wrapped Matic',
  symbol: 'WMATIC',
  logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
  underlyingTokens: [],
  type: TokenType.Base,
});

export const PROTOCOL_TOKEN = {
  [NETWORKS.mainnet.chainId]: ETH,
  [NETWORKS.ropsten.chainId]: ETH,
  [NETWORKS.rinkeby.chainId]: ETH,
  [NETWORKS.goerli.chainId]: ETH,
  [NETWORKS.kovan.chainId]: ETH,
  [NETWORKS.polygon.chainId]: MATIC,
  [NETWORKS.mumbai.chainId]: MATIC,
  [NETWORKS.optimismKovan.chainId]: ETH,
  [NETWORKS.optimism.chainId]: ETH,
  [NETWORKS.optimismGoerli.chainId]: ETH,
};

export const WRAPPED_PROTOCOL_TOKEN = {
  [NETWORKS.mainnet.chainId]: WETH,
  [NETWORKS.ropsten.chainId]: WETH,
  [NETWORKS.rinkeby.chainId]: WETH,
  [NETWORKS.goerli.chainId]: WETH,
  [NETWORKS.kovan.chainId]: WETH,
  [NETWORKS.polygon.chainId]: WMATIC,
  [NETWORKS.mumbai.chainId]: WMATIC,
  [NETWORKS.optimismKovan.chainId]: WETH,
  [NETWORKS.optimismGoerli.chainId]: WETH,
  [NETWORKS.optimism.chainId]: WETH,
};

export const getProtocolToken = (chainId: number) =>
  (PROTOCOL_TOKEN[chainId] && PROTOCOL_TOKEN[chainId](chainId)) ||
  PROTOCOL_TOKEN[NETWORKS.mainnet.chainId](NETWORKS.mainnet.chainId);
export const getWrappedProtocolToken = (chainId: number) =>
  (WRAPPED_PROTOCOL_TOKEN[chainId] && WRAPPED_PROTOCOL_TOKEN[chainId](chainId)) ||
  WRAPPED_PROTOCOL_TOKEN[NETWORKS.mainnet.chainId](NETWORKS.mainnet.chainId);
