import { sortBy, toPairs } from "lodash";
import { DateTime } from "luxon";
import { Volumes } from "./types";

// MONTHLY VOLUME GRAPH
type VolumePerTimeGraphData = {
  date: number;
  volume: number;
  label: string;
}[];

export const getMonthlyVolumeData = (volumes: Volumes) => sortBy(toPairs(volumes).reduce<VolumePerTimeGraphData>((acc, [year, yearVolume]) => ([
  ...acc,
  ...toPairs(yearVolume).reduce<{ date: number, volume: number, label: string; }[]>((monthAcc, [month, monthVolume]) => {
    const monthDate = DateTime.fromFormat(`${year}-${month}`, "yyyy-M");
    const newMonthAcc = [
      ...monthAcc,
      {
        date: monthDate.toSeconds(),
        volume: monthVolume.volume.total,
        label: `${monthDate.monthShort}/${monthDate.year.toString().substring(monthDate.year.toString().length - 2)}`
      }
    ];

    return newMonthAcc;
  }, []),
]), []), ['date'], ['desc']);

export const getTotalVolumeData = (volumes: Volumes) => toPairs(volumes).reduce<number>((acc, [year, yearVolume]) => acc + toPairs(yearVolume).reduce<number>((monthAcc, [month, monthVolume]) => monthAcc + monthVolume.volume.total, 0), 0);

export const getWeeklyVolumeData = (volumes: Volumes) => sortBy(toPairs(volumes).reduce<{ date: number, volume: number, label: string; }[]>((acc, [year, yearVolume]) => ([
  ...acc,
  ...toPairs(yearVolume).reduce<VolumePerTimeGraphData>((monthAcc, [month, monthVolume]) => ([
    ...monthAcc,
    ...toPairs(monthVolume.byWeek).reduce<VolumePerTimeGraphData>((weekAcc, [week, weekVolume]) => {
      const weekDate = DateTime.fromFormat(`${year}-${month}-${Object.keys(weekVolume.byDay).map(Number).sort()[0]}`, "yyyy-M-d");
      const newWeekAcc = [
        ...weekAcc,
        {
          date: weekDate.toSeconds(),
          volume: weekVolume.volume.total,
          label: `${weekDate.monthShort}/${weekDate.day}/${weekDate.year.toString().substring(weekDate.year.toString().length - 2)}`
        }
      ];

      return newWeekAcc;
    }, []),
  ]), []),
]), []), ['date'], ['desc']);

export const getDailyVolumeData = (volumes: Volumes) => sortBy(
  toPairs(volumes).reduce<VolumePerTimeGraphData>((acc, [year, yearVolume]) => ([
    ...acc,
    ...toPairs(yearVolume).reduce<VolumePerTimeGraphData>((monthAcc, [month, monthVolume]) => ([
      ...monthAcc,
      ...toPairs(monthVolume.byWeek).reduce<VolumePerTimeGraphData>((weekAcc, [week, weekVolume]) => ([
        ...weekAcc,
        ...toPairs(weekVolume.byDay).reduce<VolumePerTimeGraphData>((dayAcc, [day, dayVolume]) => {
          const dayDate = DateTime.fromFormat(`${year}-${month}-${day}`, "yyyy-M-d");
          const newDayAcc = [
            ...dayAcc,
            {
              date: dayDate.toSeconds(),
              volume: dayVolume.volume.total,
              label: `${dayDate.day}/${dayDate.monthShort}/${dayDate.year.toString().substring(dayDate.year.toString().length - 2)}`
            }
          ];

          return newDayAcc;
        }, []),
      ]), []),
    ]), []),
  ]), [])
  , ['date'], ['desc']);
