import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Home = () => (
  <Grid container alignItems="center" justifyItems="center">
    <Grid item xs={12}>
      <Typography variant="h4">Go to the drawer and select an action</Typography>
    </Grid>
  </Grid>
);

export default Home;
