import gql from 'graphql-tag';

const getPairLiquidity = gql`
  query getPairLiquidity($tokenA: String!, $tokenB: String!, $first: Int, $skip: Int) {
    pools(
      where: { token0: $tokenA, token1: $tokenB, liquidity_gt: 0 }
      first: $first
      skip: $skip
      orderBy: createdAtTimestamp
      orderDirection: desc
      subgraphError: "allow"
    ) {
      id
      token0Price
      token1Price
      volumeUSD
      totalValueLockedUSD
    }
  }
`;

export default getPairLiquidity;
