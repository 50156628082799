import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from 'common/navbar';
import '@rainbow-me/rainbowkit/styles.css';
import Home from 'home';
import SwapIntervalsAdmin from 'swap-intervals';
import SwapsV2Admin from 'swap-v2';
import Typography from '@mui/material/Typography';
import PositionCreationAdmin from 'create-positions';
import PositionModificationAdmin from 'modify-positions';
import OverrideOracleAdmin from 'override-oracle';
import DCASubgraph, { createClient } from 'utils/dcaSubgraphApolloClient';
import { ApolloProvider } from '@apollo/client';
import RemoveAllowanceAdmin from 'remove-allowance';
import WhitelistAdmin from 'whitelist';
import TokenChecklistAdmin from 'token-checklist';
import styled from '@emotion/styled';
import { useAccount, useNetwork } from 'wagmi';
import useThemeMode from 'hooks/useThemeMode';
import generateTheme from 'theme';
import { LATEST_VERSION } from 'config/constants';
import VolumeTrackerAdmin from 'volume-tracker';
import PositionsAnalyticsAdmin from 'positions-analytics';

const StyledNavBarGridContainer = styled(Grid)`
  flex: 0;
`;

const StyledAppGridContainer = styled(Grid)`
  flex: 1;
`;

const StyledContainer = styled(Container)`
  flex: 1;
  display: flex;
`;

const StyledContainerGrid = styled(Grid)``;

const StyledNoticeGrid = styled(Grid)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const mode = useThemeMode();
  const [apolloClient, setApolloClient] = useState(DCASubgraph);
  const theme = generateTheme(mode);

  React.useEffect(() => {
    setApolloClient(
      createClient({
        chainId: chain?.id || 10,
        version: LATEST_VERSION,
      })
    );
  }, [chain?.id]);

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <StyledContainerGrid container direction="column">
            <StyledNavBarGridContainer item xs={12}>
              <NavBar />
            </StyledNavBarGridContainer>
            {!isConnected && (
              <StyledNoticeGrid item xs={12}>
                <Typography variant="h3">Connect wallet to start using</Typography>
              </StyledNoticeGrid>
            )}
            {isConnected && (
              <StyledAppGridContainer item xs={12}>
                <StyledContainer>
                  <Routes>
                    <Route path="/swap-v2" Component={SwapsV2Admin} />
                    <Route path="/createposition" Component={PositionCreationAdmin} />
                    <Route path="/modifyPosition" Component={PositionModificationAdmin} />
                    <Route path="/swapintervals" Component={SwapIntervalsAdmin} />
                    <Route path="/override-oracle" Component={OverrideOracleAdmin} />
                    <Route path="/remove-allowance" Component={RemoveAllowanceAdmin} />
                    <Route path="/whitelist-tokens" Component={WhitelistAdmin} />
                    <Route path="/token-checklist" Component={TokenChecklistAdmin} />
                    <Route path="/volume-tracker" Component={VolumeTrackerAdmin} />
                    <Route path="/position-analytics" Component={PositionsAnalyticsAdmin} />
                    {/* <Route path="/terminate-many">
                      <TerminateManyAdmin />
                    </Route> */}
                    <Route path="/*" Component={Home} />
                  </Routes>
                </StyledContainer>
              </StyledAppGridContainer>
            )}
          </StyledContainerGrid>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
