import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { MEAN_API_URL, MEAN_GRAPHQL_URL, PositionVersions, UNI_GRAPHQL_URL } from 'config/constants';
import { ChainId, PriceResult, SOURCES_METADATA, SourceId, TokenAddress, buildSDK, getChainByKey } from '@mean-finance/sdk';
import useDCATokenList, { DCAToken } from 'hooks/useDCATokenLists';
import LinearProgress from '@mui/material/LinearProgress';
import TokenPicker, { getGhTokenListLogoUrl } from './token-picker';
import { Alert, Chip, Step, StepLabel, Stepper, Switch } from '@mui/material';
import ComposedTokenIcon from 'common/composed-token-icon';
import { toToken } from 'utils/currency';
import { find, findIndex, isUndefined, sortBy, toPairs, uniq } from 'lodash';
import { TokenType } from 'types';
import { createClient } from 'utils/dcaSubgraphApolloClient';
import gqlFetchAll from 'utils/gqlFetchAll';
import { GetPairsByTokenResponseData, getPairsByTokenA, getPairsByTokenB } from 'graphql/getPairs';
import getPairPositions, { GetPairPositionsResponseData } from 'graphql/getPairPositions';
import getPositionsSwaps, { GetPositionsSwapsResponseData, GetPositionsSwapsData, SwapDataToken, SwapDataBaseToken } from 'graphql/getPositionsSwap';
import { QontoConnector, QontoStepIcon } from './stepper';
import { formatUnits } from 'viem';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Volumes, BaseToken, ByTokenVolumeItem, VolumeByToken, VolumeItem, BaseVolume, YearsVolume, IdWithVersionAndChainId, SwapsWithVersionAndChainId, ChainIdAndVersionRecord } from './types';
import { getDailyVolumeData, getMonthlyVolumeData, getTotalVolumeData, getWeeklyVolumeData } from './graphs';

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
`;

const StyledChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

const sdk = buildSDK({
  dca: {
    customAPIUrl: MEAN_API_URL,
  },
  provider: {
    source: {
      type: 'prioritized',
      sources: [{ type: 'public-rpcs' }],
    },
  },
  quotes: {
    defaultConfig: { global: { disableValidation: true }, custom: { squid: { integratorId: 'meanfinance-api' } } },
    sourceList: {
      type: 'overridable-source-list',
      lists: {
        default: {
          type: 'local',
        },
        overrides: [
          {
            list: {
              type: 'batch-api',
              baseUri: ({ chainId }: { chainId: number }) => `${MEAN_API_URL}/v1/swap/networks/${chainId}/quotes/`,
              sources: SOURCES_METADATA,
            },
            sourceIds: ['okx-dex', '1inch', 'uniswap', 'rango', '0x', 'changelly', 'dodo', 'barter', 'enso'],
          },
        ],
      },
    },
  },
  price: {
    source: {
      type: 'cached',
      config: {
        expiration: {
          useCachedValue: { ifUnder: '1m' },
          useCachedValueIfCalculationFailed: { ifUnder: '5m' },
        },
        maxSize: 20000,
      },
      underlyingSource: {
        type: 'defi-llama',
      },
    },
  },
});

const steps = [
  {
    done: 'Pairs fetched',
    current: 'Fetching pairs',
    pending: 'Pairs will be fetched',
  },
  {
    done: 'Positions fetched',
    current: 'Fetching positions',
    pending: 'Positions will be fetched',
  },
  {
    done: 'Swaps fetched',
    current: 'Fetching swaps',
    pending: 'Swaps will be fetched',
  },
  {
    done: 'Prices fetched',
    current: 'Fetching prices',
    pending: 'Prices will be fetched',
  },
];
interface buildTimeVolumeProps {
  passedVolumes: Volumes;
  swapYear: number;
  swapMonth: number;
  swapWeek: number;
  swapDay: number;
  tokenFromForPrice: BaseToken,
  tokenToForPrice: BaseToken,
  volumeFrom: ByTokenVolumeItem,
  volumeTo: ByTokenVolumeItem,
  chainId: number;
  version: PositionVersions,
  volume: number,
  byToken: VolumeByToken,
};

const buildTimeVolume = ({
  passedVolumes,
  swapYear,
  swapMonth,
  swapWeek,
  swapDay,
  tokenFromForPrice,
  tokenToForPrice,
  volumeFrom,
  volumeTo,
  chainId,
  version,
  volume,
  byToken,
}: buildTimeVolumeProps) => {
  const volumes = { ...passedVolumes };

  const initializeVolume = () => {
    const initialVolume: VolumeItem = {
      total: volume,
      // @ts-ignore
      byToken: {
        [chainId]: {
          [version]: {
            [tokenFromForPrice.address]: volumeFrom,
            [tokenToForPrice.address]: volumeTo,
          },
        },
      },
    };
    return initialVolume;
  };

  const updateOrCreateTokenVolume = (existingVolume: ByTokenVolumeItem | undefined, passedVolume: ByTokenVolumeItem) => {
    if (existingVolume) {
      const newVolume = {
        ...existingVolume,
      }
      existingVolume.bought.amount += passedVolume.bought.amount;
      existingVolume.bought.amountUsd += passedVolume.bought.amountUsd;
      existingVolume.sold.amount += passedVolume.sold.amount;
      existingVolume.sold.amountUsd += passedVolume.sold.amountUsd;
      return newVolume;
    } else {
      return {
        bought: {
          amount: passedVolume.bought.amount,
          amountUsd: passedVolume.bought.amountUsd,
        },
        sold: {
          amount: passedVolume.sold.amount,
          amountUsd: passedVolume.sold.amountUsd,
        }
      }
    }
  };

  const updateOrCreateVolume = <T extends BaseVolume>(existingVolume: T | undefined, extraParams?: any): T => {
    if (existingVolume) {
      const existingVolumeItem = existingVolume.volume;
      const newVolume = {
        ...existingVolumeItem,
      };

      newVolume.total += volume;
      const chainVolume = newVolume.byToken[chainId] || {};
      let versionVolume = chainVolume[version] || {};
      versionVolume[tokenFromForPrice.address] = updateOrCreateTokenVolume(versionVolume[tokenFromForPrice.address], volumeFrom);
      versionVolume[tokenToForPrice.address] = updateOrCreateTokenVolume(versionVolume[tokenToForPrice.address], volumeTo);
      chainVolume[version] = versionVolume;
      newVolume.byToken[chainId] = chainVolume;

      return {
        ...extraParams,
        ...existingVolume,
        volume: newVolume,
      };
    } else {

      return {
        volume: initializeVolume(),
        ...extraParams,
      }
    }
  };

  const yearVolume: YearsVolume = volumes[swapYear] || {};
  let monthVolume = updateOrCreateVolume(yearVolume[swapMonth], { byWeek: {} });
  let weekVolume = updateOrCreateVolume(monthVolume.byWeek[swapWeek], { byDay: {} });
  let dayVolume = updateOrCreateVolume(weekVolume.byDay[swapDay]);

  volumes[swapYear] = yearVolume;
  volumes[swapYear][swapMonth] = monthVolume;
  volumes[swapYear][swapMonth].byWeek[swapWeek] = weekVolume;
  volumes[swapYear][swapMonth].byWeek[swapWeek].byDay[swapDay] = dayVolume;


  return volumes;
};

const BATCH_SIZE = 10; // Number of requests per batch
const BATCH_WAIT_TIME = 1000; // Delay between batches in milliseconds

const VolumeTrackerAdmin = () => {
  const { loading: isLoadingList, result: tokens, errors } = useDCATokenList();
  const [openPicker, setOpenPicker] = useState(false);
  const [includeYield, setIncludeYield] = useState(true);
  const [selectedTokens, setSelectedTokens] = useState<DCAToken[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(0);
  const [pairs, setPairs] = useState<IdWithVersionAndChainId[]>([]);
  const [positions, setPositions] = useState<IdWithVersionAndChainId[]>([]);
  const [swaps, setSwaps] = useState<SwapsWithVersionAndChainId[]>([]);
  const [volumes, setVolumes] = useState<Volumes>({});
  const [batchSize, setBatchSize] = useState(BATCH_SIZE);
  const [batchWaitTime, setBatchWaitTime] = useState(BATCH_WAIT_TIME);
  const [batches, setBatches] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [fetchPriceErrors, setFetchPriceErrors] = useState<{ timestamp: number, chainId: number, token: SwapDataBaseToken; calculatedVolume?: boolean }[]>([]);
  const [showAlert, setShowAlert] = useState(true);
  // const [prices, setSwaps] = useState<SwapsWithVersionAndChainId[]>([]);

  const getSdkPriceRequest = React.useCallback(async (data: { addresses: Record<number, string[]>; timestamp: number }) => {
    return sdk.priceService.getHistoricalPrices({
      addresses: data.addresses,
      timestamp: data.timestamp,
    })
  }, []);

  const processBatch = React.useCallback(async (batch: { addresses: Record<number, string[]>; timestamp: number }[]) => {
    const promises = batch.map(async (data) => {
      try {
        const value = await getSdkPriceRequest(data);
        return { status: 'fulfilled', value } as PromiseSettledResult<Record<ChainId, Record<TokenAddress, PriceResult>>>;
      } catch (reason) {
        return { status: 'rejected', reason } as PromiseSettledResult<Record<ChainId, Record<TokenAddress, PriceResult>>>;
      }
    });

    return Promise.all(promises);
  }, [getSdkPriceRequest])

  const processAllSdkRequests = React.useCallback(async (data: { addresses: Record<number, string[]>; timestamp: number }[], batchSize: number, delay: number) => {
    const totalBatches = Math.ceil(data.length / batchSize);
    const results: PromiseSettledResult<Record<ChainId, Record<TokenAddress, PriceResult>>>[] = [];

    setBatches(totalBatches);
    for (let i = 0; i < totalBatches; i++) {
      setCurrentBatch(prevCurrentBatch => prevCurrentBatch + 1);
      const batchStart = i * batchSize;
      const batchEnd = batchStart + batchSize;
      const batch = data.slice(batchStart, batchEnd);

      const batchResults = await processBatch(batch);
      results.push(...batchResults);

      if (i < totalBatches - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }

    return results;
  }, [processBatch])


  const checkVolume = useCallback(async () => {
    setCurrentStep(0);
    setPairs([]);
    setPositions([]);
    setSwaps([]);
    setBatches(0);
    setCurrentBatch(0);
    setFetchPriceErrors([]);
    setShowAlert(true);
    const timestampFrom = startDate !== '' && DateTime.fromFormat(startDate, "yyyy-MM-dd").toSeconds() || null;
    const timestampTo = endDate !== '' && DateTime.fromFormat(endDate, "yyyy-MM-dd").toSeconds() || null;
    let tokensToFetch = [...selectedTokens];
    // first we get all the yield tokens if the user selected them
    if (includeYield) {
      tokensToFetch = tokensToFetch.reduce((acc, token) => {
        const foundTokens = tokens?.filter((tokenToCheck) => {
          if (tokenToCheck.type !== TokenType.Yield) {
            return false;
          }

          return !!find(tokenToCheck.underlyingTokens, { address: token.address });
        });

        if (foundTokens?.length) {
          return [...acc, ...foundTokens];
        }

        return acc;
      }, selectedTokens);
    }

    // first lets get all the version we are going to need to fetch
    const versions = uniq(tokensToFetch.map((token) => token.version));
    // now all the chains that we are going to need to fetch
    const chains = uniq(tokensToFetch.map((token) => token.chainId));

    // now that we have that, lets get all the pairs that we need to fetch
    const pairPromises: Promise<IdWithVersionAndChainId[]>[] = [];
    versions.forEach((version) => {
      chains.forEach((chainId) => {
        if (!MEAN_GRAPHQL_URL[version] || !MEAN_GRAPHQL_URL[version][chainId]) {
          return;
        }

        const client = createClient({
          chainId: Number(chainId),
          version,
        });

        const tokensInChainAndVersion = tokensToFetch.filter(
          (token) => token.chainId === chainId && token.version === version
        );

        // Two promises, one for tokenA another for tokenB
        pairPromises.push(
          gqlFetchAll<GetPairsByTokenResponseData>(
            client,
            getPairsByTokenA,
            { tokenA: tokensInChainAndVersion.map((token) => token.address) },
            'pairs'
          ).then((tokens) =>
            tokens.data!.pairs.map((pair) => ({
              id: pair.id,
              chainId,
              version,
            }))
          )
        );
        pairPromises.push(
          gqlFetchAll<GetPairsByTokenResponseData>(
            client,
            getPairsByTokenB,
            { tokenB: tokensInChainAndVersion.map((token) => token.address) },
            'pairs'
          ).then((tokens) =>
            tokens.data!.pairs.map((pair) => ({
              id: pair.id,
              chainId,
              version,
            }))
          )
        );
      });
    });

    const pairPromisesResult = await Promise.allSettled(pairPromises);

    const pairs = pairPromisesResult.reduce(
      (acc, current) => (current.status === 'fulfilled' ? [...acc, ...current.value] : acc),
      []
    );
    setCurrentStep((prevStep) => prevStep + 1);
    setPairs(pairs);

    const positionPromises: Promise<IdWithVersionAndChainId[]>[] = [];
    // now that we got the pairs, we need to fetch the positions
    versions.forEach((version) => {
      chains.forEach((chainId) => {
        if (!MEAN_GRAPHQL_URL[version] || !MEAN_GRAPHQL_URL[version][chainId]) {
          return;
        }

        const client = createClient({
          chainId: Number(chainId),
          version,
        });

        const pairsInChainAndVersion = pairs.filter((pair) => pair.chainId === chainId && pair.version === version);

        positionPromises.push(
          gqlFetchAll<GetPairPositionsResponseData>(
            client,
            getPairPositions,
            {
              pairs: pairsInChainAndVersion.map((pair) => pair.id),
              timestampFrom: timestampFrom || 0,
              timestampTo: timestampTo || Math.round(DateTime.fromMillis(Date.now()).toSeconds()),
            },
            'positions'
          ).then((positions) =>
            positions.data!.positions.map((position) => ({
              id: position.id,
              chainId,
              version,
            }))
          )
        );
      });
    });

    const positionsPromisesResult = await Promise.allSettled(positionPromises);

    const positions = positionsPromisesResult.reduce(
      (acc, current) => (current.status === 'fulfilled' ? [...acc, ...current.value] : acc),
      []
    );
    setCurrentStep((prevStep) => prevStep + 1);
    setPositions(positions);

    const swapPromises: Promise<SwapsWithVersionAndChainId[]>[] = [];

    // now that we got the positions we need to fetch ALL the transaction actions
    versions.forEach((version) => {
      chains.forEach((chainId) => {
        if (!MEAN_GRAPHQL_URL[version] || !MEAN_GRAPHQL_URL[version][chainId]) {
          return;
        }

        const client = createClient({
          chainId: Number(chainId),
          version,
        });

        const positionsInChainsAndVersions = positions.filter(
          (position) => position.chainId === chainId && position.version === version
        );

        // lets split the positions in batches
        const chunks = chunkArray(positionsInChainsAndVersions, 3000);

        chunks.forEach((positionsChunk) => {
          swapPromises.push(
            gqlFetchAll<GetPositionsSwapsResponseData>(
              client,
              getPositionsSwaps,
              { positions: positionsChunk.map((position) => position.id) },
              'swappedActions'
            ).then((positions) =>
              positions.data!.swappedActions.map((swap) => ({
                ...swap,
                chainId,
                version,
                swapped: BigInt(swap.swapped),
                rate: BigInt(swap.rate),
              }))
            )
          );
        });
      });
    });

    const swapsPromisesResult = await Promise.allSettled(swapPromises);

    const swaps = swapsPromisesResult.reduce(
      (acc, current) => (current.status === 'fulfilled' ? [...acc, ...current.value] : acc),
      []
    ).map<SwapsWithVersionAndChainId>(swap => ({
      ...swap,
      swapped: BigInt(swap.swappedUnderlying || swap.swapped),
      rate: BigInt(swap.rateUnderlying || swap.rate),
    }));
    setCurrentStep((prevStep) => prevStep + 1);
    setSwaps(swaps);

    // Now we need to fetch the prices for each of the tokens at the moment of the swap, but only for the ones that do not have yield
    // Record<Timestamp<Record<ChainId, Address[]>>
    const datesAndTokensNotUniq = swaps.reduce<Record<number, Record<number, string[]>>>((acc, current) => {
      const newAcc = {
        ...acc,
      };

      const tokenFrom = current.position.from;
      const tokenTo = current.position.to;

      const tokensInSwap = [
        tokenFrom.type === TokenType.Base ? tokenFrom.address : tokenFrom.underlyingTokens[0].address,
        tokenTo.type === TokenType.Base ? tokenTo.address : tokenTo.underlyingTokens[0].address,
      ];
      const timestamp = Number(current.createdAtTimestamp);

      if (newAcc[timestamp]) {
        if (newAcc[timestamp][current.chainId]) {
          newAcc[timestamp][current.chainId] = [
            ...newAcc[timestamp][current.chainId],
            ...tokensInSwap,
          ];
        } else {
          newAcc[timestamp][current.chainId] = tokensInSwap;
        }
      } else {
        newAcc[timestamp] = {
          [current.chainId]: tokensInSwap,
        };
      }

      return newAcc;
    }, {});

    // uniq(datesAndTokensNotUniq[current])
    const datesAndTokens = (Object.keys(datesAndTokensNotUniq) as unknown as number[]).reduce<Record<number, Record<number, string[]>>>((acc, current) => ({
      ...acc,
      [current]: (Object.keys(datesAndTokensNotUniq[current]) as unknown as number[]).reduce<Record<number, string[]>>((subAcc, subCurrent) => ({
        ...subAcc,
        [subCurrent]: uniq(datesAndTokensNotUniq[current][subCurrent]),
      }), {}),
    }), {})

    // Now lets use the sdk to fetch multiple prices
    const sdkData: { addresses: Record<number, string[]>; timestamp: number }[] = [];
    (Object.keys(datesAndTokens) as unknown as number[]).forEach(timestamp => {
      const chainsAndTokens = datesAndTokens[timestamp];
      sdkData.push({
        addresses: chainsAndTokens,
        timestamp,
      })
    });
    const sdkPricePromiseResult = await processAllSdkRequests(sdkData, batchSize, batchWaitTime);
    setCurrentStep((prevStep) => prevStep + 1);

    const datesTokensAndPrices = (Object.keys(datesAndTokens) as unknown as number[]).reduce<Record<number, Record<number, Record<string, PriceResult>>>>(
      (acc, current, index) => {
        const currentPromise = sdkPricePromiseResult[index]
        if (currentPromise.status === 'rejected') {
          console.log('rejected promise', currentPromise);
          return acc;
        };
        const newAcc = {
          ...acc,
        };

        const timestamp = current;
        const datesAndTokensOnTimestamp = datesAndTokens[timestamp];
        const sdkResponse = currentPromise.value;

        newAcc[timestamp] = {
          ...(Object.keys(datesAndTokensOnTimestamp) as unknown as number[]).reduce<Record<number, Record<string, PriceResult>>>((subAcc, chainId) => ({
            ...subAcc,
            [chainId]: datesAndTokensOnTimestamp[chainId].reduce<Record<string, PriceResult>>((subSubAcc, tokenAddress) => ({
              ...subSubAcc,
              [tokenAddress]: sdkResponse[chainId][tokenAddress],
            }), {})
          }), {})
        }

        return newAcc;
      }
    , {})

    const unfetchedPrices: { timestamp: number, chainId: number, token: SwapDataBaseToken, calculatedVolume?: boolean }[] = [];

    const monthlyVolumes = swaps.reduce<Volumes>((acc, current) => {
      const newAcc = {
        ...acc,
      };

      const tokenFrom = current.position.from;
      const tokenTo = current.position.to;

      const tokenFromForPrice = tokenFrom.type === TokenType.Base ? tokenFrom : tokenFrom.underlyingTokens[0];
      const tokenToForPrice = tokenTo.type === TokenType.Base ? tokenTo : tokenTo.underlyingTokens[0];

      const version = current.version;
      const chainId = current.chainId;
      const timestamp = Number(current.createdAtTimestamp)
      const swapDate = DateTime.fromSeconds(timestamp);
      const swapDay = swapDate.day;
      const swapWeek = swapDate.weekNumber;
      const swapMonth = swapDate.month;
      const swapYear = swapDate.year;
      let volume;
      let volumeFrom;
      let volumeTo;

      try {
        volume = Number(formatUnits(current.swapped, tokenToForPrice.decimals)) * datesTokensAndPrices[timestamp][current.chainId][tokenToForPrice.address].price;

        volumeTo = {
          bought: {
            amount: current.swapped,
            amountUsd: Number(formatUnits(current.swapped, tokenToForPrice.decimals)) * datesTokensAndPrices[timestamp][current.chainId][tokenToForPrice.address].price,
          },
          sold: {
            amount: BigInt(0),
            amountUsd: 0,
          },
        };
      } catch(e) {
        console.log('Failed to fetch price for', tokenToForPrice.symbol);
        unfetchedPrices.push({
          timestamp,
          chainId,
          token: tokenToForPrice,
        });
        volumeTo = {
          bought: {
            amount: current.swapped,
            amountUsd: 0,
          },
          sold: {
            amount: BigInt(0),
            amountUsd: 0,
          },
        };
      }

      try {
        volumeFrom = {
          bought: {
            amount: BigInt(0),
            amountUsd: 0,
          },
          sold: {
            amount: current.rate,
            amountUsd: Number(formatUnits(current.rate, tokenFromForPrice.decimals)) * datesTokensAndPrices[timestamp][current.chainId][tokenFromForPrice.address].price,
          },
        };

        if (isUndefined(volume)) {
          volume = Number(formatUnits(current.rate, tokenFromForPrice.decimals)) * datesTokensAndPrices[timestamp][current.chainId][tokenFromForPrice.address].price;
          // Remove warning since we were able to calculate it
          unfetchedPrices.pop();
        }
      } catch(e) {
        unfetchedPrices.push({
          timestamp,
          chainId,
          token: tokenFromForPrice,
          calculatedVolume: !isUndefined(volume),
        });
        volume = volume || 0;
        volumeFrom = {
          bought: {
            amount: BigInt(0),
            amountUsd: 0,
          },
          sold: {
            amount: current.rate,
            amountUsd: 0,
          },
        };
      }
      const byToken = {
        [current.chainId]: {
          [current.version]: {
            [tokenFromForPrice.address]: volumeFrom,
            [tokenToForPrice.address]: volumeTo,
          }
        }
      } as unknown as ChainIdAndVersionRecord<Record<string, {
        bought: {
          amount: bigint, amountUsd: number
        };
        sold: {
          amount: bigint, amountUsd: number
        }
      }>>;

      const newNewAcc = buildTimeVolume({
        passedVolumes: newAcc,
        swapDay,
        swapMonth,
        swapWeek,
        swapYear,
        chainId,
        version,
        volumeFrom,
        volume,
        volumeTo,
        byToken,
        tokenFromForPrice,
        tokenToForPrice,
      })
      return newNewAcc
    }, {});

    setFetchPriceErrors(unfetchedPrices);

    setVolumes(monthlyVolumes);
  }, [selectedTokens, tokens, startDate, endDate, includeYield, batchSize, batchWaitTime]);

  const onCheckVolume = useCallback(() => {
    try {
      checkVolume();
    } catch(e) {
      console.log('Failed checking volume because of', e)
    }
  }, [checkVolume]);

  const onTockenPickerClose = useCallback(() => {
    setOpenPicker(false);
  }, [setOpenPicker]);

  const onSelectTokens = useCallback(
    (tokens: DCAToken[]) => {
      setSelectedTokens(tokens);
    },
    [setSelectedTokens]
  );

  const handleOpenPicker = useCallback(() => {
    setOpenPicker(true);
  }, [setOpenPicker]);

  const handleDeselect = (token: DCAToken) => {
    const foundIndex = findIndex(selectedTokens, {
      address: token.address,
      chainId: token.chainId,
      version: token.version,
    });
    if (foundIndex === -1) {
      return;
    } else {
      setSelectedTokens((prevSelected) =>
        prevSelected.filter(
          (prevToken) =>
            token.symbol !== prevToken.symbol ||
            token.chainId !== prevToken.chainId ||
            token.version !== prevToken.version ||
            token.address !== prevToken.address ||
            token.chainId !== prevToken.chainId
        )
      );
    }
  };

  const onToggleIncludeYield = useCallback(() => {
    setIncludeYield((oldIncludeYield) => !oldIncludeYield);
    setSelectedTokens([]);
  }, []);

  const handleOnChangeBatchSize = useCallback((size: number) => {
    setBatchSize(size);
  }, []);
  const handleOnChangeBatchWaitTime = useCallback((time: number) => {
    setBatchWaitTime(time);
  }, []);
  const handleOnChangeEndDate = useCallback((date: string) => {
    setEndDate(date);
  }, []);
  const handleOnChangeStartDate = useCallback((date: string) => {
    setStartDate(date);
  }, []);

  const volumeTotal = React.useMemo(() => getTotalVolumeData(volumes), [volumes]);
  const volumesByMonth = React.useMemo(() => getMonthlyVolumeData(volumes), [volumes]);
  const volumesByWeek = React.useMemo(() => getWeeklyVolumeData(volumes), [volumes]);
  const volumesByDay = React.useMemo(() => getDailyVolumeData(volumes), [volumes]);

  console.log(volumes, volumesByDay);

  return (
    <>
      <TokenPicker tokens={tokens || []} onChange={onSelectTokens} onClose={onTockenPickerClose} isOpen={openPicker} />
      <Grid container alignItems="center" justifyItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Get volume tracked by tokens</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Grid container spacing={2}>
              {isLoadingList && (
                <Grid item xs={12}>
                  Loading Tokens
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                <Button variant="contained" color="primary" disabled={isLoadingList} onClick={handleOpenPicker}>
                  Select Tokens
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Selected Tokens:</Typography>
                <StyledChips>
                  {selectedTokens.map((token) => {
                    const network = getChainByKey(token.chainId)!;

                    const networkToken = toToken({
                      address: network.wToken,
                      chainId: network.chainId,
                      logoURI: getGhTokenListLogoUrl(network.chainId, 'logo'),
                    });

                    return (
                      <Chip
                        size="small"
                        label={`${token.symbol} on ${token.version}`}
                        onDelete={() => handleDeselect(token)}
                        icon={<ComposedTokenIcon isInChip tokenTop={token} tokenBottom={networkToken} />}
                      />
                    );
                  })}
                </StyledChips>
              </Grid>
              <Grid item xs={6}>
                Include yield
                <Switch checked={includeYield} onChange={onToggleIncludeYield} name="includeYield" color="primary" />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  onChange={(event) => handleOnChangeStartDate(event.target.value)}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  onChange={(event) => handleOnChangeEndDate(event.target.value)}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="batchSize"
                  label="Sdk Price Request Batch Size"
                  type="number"
                  value={batchSize}
                  onChange={(event) => handleOnChangeBatchSize(Number(event.target.value))}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
              <TextField
                  id="batchSize"
                  label="Sdk Price Time between batches in milliseconds"
                  type="number"
                  value={batchWaitTime}
                  onChange={(event) => handleOnChangeBatchWaitTime(Number(event.target.value))}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" disabled={isLoadingList} onClick={onCheckVolume}>
                  Check Volume
                </Button>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper alternativeLabel activeStep={currentStep} connector={<QontoConnector />}>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {currentStep === index ? label.current : currentStep > index ? label.done : label.pending}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
          <StyledPaper>
            <Typography variant="h6">
              Pairs:
            </Typography>
            <Typography variant="body1">
              {pairs.length}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
          <StyledPaper>
            <Typography variant="h6">
              Positions:
            </Typography>
            <Typography variant="body1">
              {positions.length}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
          <StyledPaper>
            <Typography variant="h6">
              Swaps:
            </Typography>
            <Typography variant="body1">
              {swaps.length}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
          <StyledPaper>
            <Typography variant="h6">
              Fetched prices:
            </Typography>
            <Typography variant="body1">
              {currentBatch}/{batches}
            </Typography>
          </StyledPaper>
        </Grid>
        { !!fetchPriceErrors.filter(error => !error.calculatedVolume).length && showAlert && (
          <Grid item xs={12}>
            <Alert severity="warning" onClose={() => setShowAlert(false)}>
              <StyledColumn>
                {fetchPriceErrors.filter(error => !error.calculatedVolume).map(error => (
                  <Typography variant='body1'>
                    Failed to fetch price for {error.token.symbol || error.token.address} on timestamp {error.timestamp} for chainId {error.chainId}
                  </Typography>
                ))}
              </StyledColumn>
            </Alert>
          </Grid>
        )}
        <Grid item xs={2}>
          <StyledPaper>
          <Typography variant="h6">
              Total volume:
            </Typography>
            <Typography variant="body1">
              ${volumeTotal.toFixed(2)} USD
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={5}>
          <StyledPaper>
            <Typography variant="h6">
              Volume by Month
            </Typography>
            <ResponsiveContainer width="100%" height="100%" minHeight="300px">
              <BarChart
                width={500}
                height={300}
                data={volumesByMonth}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip contentStyle={{ background: 'rgba(97, 97, 97, 0.92)', color: 'white' }}/>
                <Legend />
                <Bar dataKey="volume" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </StyledPaper>
        </Grid>
        <Grid item xs={5}>
          <StyledPaper>
            <Typography variant="h6">
              Volume by Week
            </Typography>
            <ResponsiveContainer width="100%" height="100%" minHeight="300px">
              <BarChart
                width={500}
                height={300}
                data={volumesByWeek}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip contentStyle={{ background: 'rgba(97, 97, 97, 0.92)', color: 'white' }}/>
                <Legend />
                <Bar dataKey="volume" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6">
              Volume by Day
            </Typography>
            <ResponsiveContainer width="100%" height="100%" minHeight="300px">
              <BarChart
                width={500}
                height={300}
                data={volumesByDay}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip contentStyle={{ background: 'rgba(97, 97, 97, 0.92)', color: 'white' }}/>
                <Legend />
                <Bar dataKey="volume" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
};

export default VolumeTrackerAdmin;
