import gql from 'graphql-tag';

export interface GetPairPositionsResponseData {
  positions: {
    id: string;
  }[];
}

const getPairPositions = gql`
  query getPairPositions($pairs: [String]!, $first: Int, $timestampTo: Int, $timestampFrom: Int, $lastId: String) {
    positions(first: $first, where: { id_gt: $lastId, pair_in: $pairs, createdAtTimestamp_lt: $timestampTo, createdAtTimestamp_gt: $timestampFrom }, subgraphError: "allow") {
      id
    }
  }
`;

export default getPairPositions;
