import { Contract } from '@ethersproject/contracts';
import { TransactionResponse } from '@ethersproject/providers';

export interface SwapIntervalItem {
  description: string;
  key: number;
  value: number;
}

export enum TokenType {
  Base = 'BASE',
  Wrapped = 'WRAPPED_PROTOCOL_TOKEN',
  Yield = 'YIELD_BEARING_SHARE',
}

export type Token = {
  decimals: number;
  chainId: number;
  address: string;
  name: string;
  symbol: string;
  logoURI?: string;
  type: TokenType;
  underlyingTokens: Token[];
};

export interface GetPairResponseData {
  id: string;
  tokenA: Token;
  tokenB: Token;
  highestId: string;
  createdAtTimestamp: string;
}

export interface GetPairsResponseData {
  pairs: GetPairResponseData[];
}

export interface GetUserPosition {
  id: string;
  from: {
    id: string;
    decimals: string;
    symbol: string;
  };
  to: {
    id: string;
    decimals: string;
    symbol: string;
  };
  toWithdraw: string;
  remainingLiquidity: string;
}

export interface GetUserPositionsResponseData {
  positions: GetUserPosition[];
}

export interface AllowedToken extends Token {
  allowed: boolean;
}
export interface GetTokenListData {
  tokens: AllowedToken[];
}

export type Nullable<T> = T | null;

export class ERC20Contract extends Contract {
  balanceOf: (address: string) => Promise<bigint>;

  name: () => Promise<string>;

  decimals: () => Promise<number>;

  symbol: () => Promise<string>;

  deposit: (toDeposit: { value: string }) => Promise<TransactionResponse>;

  allowance: (address: string, contract: string) => Promise<string>;

  approve: (address: string, value: bigint) => Promise<TransactionResponse>;
}

export class MulticallContract extends Contract {
  aggregate3: (
    calls: { target: string; callData: string; allowFailure: boolean }[]
  ) => Promise<{ success: boolean; returnData: string }[]>;

  callStatic: {
    aggregate3: (
      calls: { target: string; callData: string; allowFailure: boolean }[]
    ) => Promise<{ success: boolean; returnData: string }[]>;
  };
}
