import { MEAN_GRAPHQL_URL, PositionVersions } from 'config/constants';
import getAllTokens, { GetAllTokensResponseData } from 'graphql/getAllTokens.graphql';
import findIndex from 'lodash/findIndex';
import React, { useState } from 'react';
import axios from 'axios';

import { Token } from 'types';
import { toToken } from 'utils/currency';
import { createClient } from 'utils/dcaSubgraphApolloClient';
import gqlFetchAll from 'utils/gqlFetchAll';
import find from 'lodash/find';

// export interface DCAToken extends Token {
//   inVersion: PositionVersions[];
// }

export interface TokenListResponse {
  tokens: Token[];
}

export interface DCAToken extends Token {
  version: PositionVersions;
}

export type DCATokenLists = DCAToken[];

const useDCATokenList = () => {
  const [params, setParams] = useState<{
    loading: boolean;
    result?: DCATokenLists;
    errors?: string[];
  }>({
    loading: true,
    result: undefined,
    errors: undefined,
  });

  React.useEffect(() => {
    const getTokens = async () => {
      const promises: Promise<DCAToken[]>[] = [];
      Object.values(PositionVersions).forEach((version) => {
        Object.keys(MEAN_GRAPHQL_URL[version]).forEach((chainId) => {
          const client = createClient({
            chainId: Number(chainId),
            version,
          });

          promises.push(
            gqlFetchAll<GetAllTokensResponseData>(client, getAllTokens, {}, 'tokens').then((tokens) =>
              tokens.data!.tokens.map((baseToken) => ({
                ...toToken({
                  ...baseToken,
                  chainId: Number(chainId),
                }),
                version,
              }))
            )
          );
        });
      });

      const meanTokens = axios.get<TokenListResponse>(
        'https://raw.githubusercontent.com/Mean-Finance/token-list/main/mean-finance.tokenlist.json'
      );

      const tokensPromise = Promise.allSettled(promises);

      const [meanListResult, tokensResult] = await Promise.allSettled([meanTokens, tokensPromise]);

      if (tokensResult.status === 'rejected') {
        setParams({
          loading: false,
          result: undefined,
          errors: ['Error fetching graphs token list'],
        });
        return;
      }

      const meanList =
        meanListResult.status !== 'rejected'
          ? meanListResult.value.data.tokens.map((token) => ({
              ...token,
              address: token.address.toLowerCase(),
              symbol: token.symbol.toLowerCase(),
            }))
          : [];

      const errors: string[] = [];
      const reducedResult = tokensResult.value.reduce<DCATokenLists>((acc, current) => {
        if (current.status === 'rejected') {
          errors.push(`Failed to fetch tokenlist because of ${current.reason}`);
          return acc;
        }

        const mappedValue = current.value.map((token) => {
          let foundToken = find(meanList, { address: token.address.toLowerCase() });

          if (!foundToken) {
            foundToken = find(meanList, { symbol: token.symbol.toLowerCase() });
          }

          if (foundToken) {
            return {
              ...token,
              logoURI: foundToken.logoURI,
            };
          }

          return token;
        });

        const newAcc = [...acc, ...mappedValue];

        // const list = current.value;

        // list.forEach(token => {
        //   const foundIndex = findIndex(newAcc, { address: token.address, chainId: token.chainId })

        //   if (foundIndex === -1) {
        //     newAcc.push({
        //       ...token,
        //       // inVersion: [token.version],
        //     });
        //   } else {
        //     if (!newAcc[foundIndex].inVersion.includes(token.version)) {
        //       newAcc[foundIndex].inVersion.push(token.version);
        //     }
        //   }
        // });
        return newAcc;
      }, []);

      setParams({
        loading: false,
        result: reducedResult,
        errors,
      });

      return reducedResult;
    };

    getTokens();
  }, []);

  return params;
};

export default useDCATokenList;
