import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { MEAN_API_URL, MEAN_GRAPHQL_URL, NETWORKS, PositionVersions, UNI_GRAPHQL_URL } from 'config/constants';
import { ChainId, PriceResult, SOURCES_METADATA, SourceId, TokenAddress, buildSDK, getChainByKey } from '@mean-finance/sdk';
import useDCATokenList, { DCAToken } from 'hooks/useDCATokenLists';
import LinearProgress from '@mui/material/LinearProgress';
import TokenPicker, { getGhTokenListLogoUrl } from './token-picker';
import { Alert, Chip, Step, StepLabel, Stepper, Switch } from '@mui/material';
import ComposedTokenIcon from 'common/composed-token-icon';
import { toToken } from 'utils/currency';
import { find, findIndex, isUndefined, sortBy, toPairs, union, uniq } from 'lodash';
import { TokenType } from 'types';
import { createClient } from 'utils/dcaSubgraphApolloClient';
import gqlFetchAll from 'utils/gqlFetchAll';
import { GetPairsByTokenResponseData, getPairsByTokenA, getPairsByTokenB } from 'graphql/getPairs';
import getPairPositions, { GetPairPositionsResponseData } from 'graphql/getPairPositions';
import getPositionsSwaps, { GetPositionsSwapsResponseData, GetPositionsSwapsData, SwapDataToken, SwapDataBaseToken } from 'graphql/getPositionsSwap';
import { QontoConnector, QontoStepIcon } from './stepper';
import { formatUnits } from 'viem';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Volumes, BaseToken, ByTokenVolumeItem, VolumeByToken, VolumeItem, BaseVolume, YearsVolume, IdWithVersionAndChainId, SwapsWithVersionAndChainId, ChainIdAndVersionRecord } from './types';
import { getDailyVolumeData, getMonthlyVolumeData, getTotalVolumeData, getWeeklyVolumeData } from './graphs';
import { GetPositionsResponseData, getPositions } from 'graphql/getPositions';

export const ONE_MINUTE = 60;
export const FIVE_MINUTES = ONE_MINUTE * 5
export const FIFTEEN_MINUTES = FIVE_MINUTES * 3;
export const THIRTY_MINUTES = FIFTEEN_MINUTES * 2;
export const ONE_HOUR = THIRTY_MINUTES * 2;
export const FOUR_HOURS = ONE_HOUR * 4;
export const ONE_DAY = FOUR_HOURS * 6;
export const ONE_WEEK = ONE_DAY * 7;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
`;

const StyledChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

const sdk = buildSDK({
  dca: {
    customAPIUrl: MEAN_API_URL,
  },
  provider: {
    source: {
      type: 'prioritized',
      sources: [{ type: 'public-rpcs' }],
    },
  },
  quotes: {
    defaultConfig: { global: { disableValidation: true }, custom: { squid: { integratorId: 'meanfinance-api' } } },
    sourceList: {
      type: 'overridable-source-list',
      lists: {
        default: {
          type: 'local',
        },
        overrides: [
          {
            list: {
              type: 'batch-api',
              baseUri: ({ chainId }: { chainId: number }) => `${MEAN_API_URL}/v1/swap/networks/${chainId}/quotes/`,
              sources: SOURCES_METADATA,
            },
            sourceIds: ['okx-dex', '1inch', 'uniswap', 'rango', '0x', 'changelly', 'dodo', 'barter', 'enso'],
          },
        ],
      },
    },
  },
  price: {
    source: {
      type: 'cached',
      config: {
        expiration: {
          useCachedValue: { ifUnder: '1m' },
          useCachedValueIfCalculationFailed: { ifUnder: '5m' },
        },
        maxSize: 20000,
      },
      underlyingSource: {
        type: 'defi-llama',
      },
    },
  },
});

const steps = [
  {
    done: 'Positions fetched',
    current: 'Fetching positions',
    pending: 'Positions will be fetched',
  },
];

const PositionsAnalyticsAdmin = () => {
  const [openPicker, setOpenPicker] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [positions, setPositions] = useState<IdWithVersionAndChainId[]>([]);
  const [showAlert, setShowAlert] = useState(true);
  // const [prices, setSwaps] = useState<SwapsWithVersionAndChainId[]>([]);

  const checkVolume = useCallback(async () => {
    setCurrentStep(0);
    setPositions([]);
    setShowAlert(true);

    // first lets get all the version we are going to need to fetch
    const versions = [PositionVersions.BETA, PositionVersions.VULN, PositionVersions.YIELD, PositionVersions.YIELDLESS];
    // now all the chains that we are going to need to fetch
    const chains = [NETWORKS.optimism.chainId, NETWORKS.polygon.chainId, NETWORKS.arbitrum.chainId, NETWORKS.mainnet.chainId, NETWORKS.bsc.chainId];

    // now that we have that, lets get all the pairs that we need to fetch
    const positionPromises: Promise<IdWithVersionAndChainId[]>[] = [];
    versions.forEach((version) => {
      chains.forEach((chainId) => {
        if (!MEAN_GRAPHQL_URL[version] || !MEAN_GRAPHQL_URL[version][chainId]) {
          return;
        }

        const client = createClient({
          chainId: Number(chainId),
          version,
        });

        // Two promises, one for tokenA another for tokenB
        positionPromises.push(
          gqlFetchAll<GetPositionsResponseData>(
            client,
            getPositions,
            {},
            'positions'
          ).then((positions) =>
            positions.data!.positions.map((position) => ({
              chainId,
              version,
              ...position,
            }))
          )
        );
      });
    });

    const positionsPromisesResult = await Promise.allSettled(positionPromises);

    const positions = positionsPromisesResult.reduce(
      (acc, current) => (current.status === 'fulfilled' ? [...acc, ...current.value] : acc),
      []
    );

    setCurrentStep((prevStep) => prevStep + 1);
    setPositions(positions);
  }, []);

  const onCheckVolume = useCallback(() => {
    try {
      checkVolume();
    } catch(e) {
      console.log('Failed checking volume because of', e)
    }
  }, [checkVolume]);

  const positionCreationsByIntervalandChain = React.useMemo(() => {
    return positions.reduce<Record<number,Record<number,number>>>((acc, position) => {
      const newAcc = {
        ...acc,
      };

      const interval = Number(position.swapInterval.id);
      const initialSwaps = Number(position.history[0].remainingSwaps);

      if (!newAcc[interval]) {
        newAcc[interval] = {};
      }

      if (!newAcc[interval][initialSwaps]) {
        newAcc[interval][initialSwaps] = 0;
      }

      newAcc[interval][initialSwaps] += 1;

      return newAcc
    }, {});
  }, [positions]);

  const fourHourPositions = (positionCreationsByIntervalandChain && positionCreationsByIntervalandChain[FOUR_HOURS]) || {};
  const dailyPositions = (positionCreationsByIntervalandChain && positionCreationsByIntervalandChain[ONE_DAY]) || {};
  const weeklyPositions = (positionCreationsByIntervalandChain && positionCreationsByIntervalandChain[ONE_WEEK]) || {};

  const fourHoursPositionsCreatedWith7 = (fourHourPositions && fourHourPositions[7]) || 0;
  const dailyPositionsCreatedWith7 = (dailyPositions && dailyPositions[7]) || 0;
  const weeklyPositionsCreatedWith7 = (weeklyPositions && weeklyPositions[7]) || 0;
  const fourHoursPositionsCreatedWith15 = (fourHourPositions && fourHourPositions[15]) || 0;
  const dailyPositionsCreatedWith15 = (dailyPositions && dailyPositions[15]) || 0;
  const weeklyPositionsCreatedWith15 = (weeklyPositions && weeklyPositions[15]) || 0;
  const fourHoursPositionsCreatedWith30 = (fourHourPositions && fourHourPositions[30]) || 0;
  const dailyPositionsCreatedWith30 = (dailyPositions && dailyPositions[30]) || 0;
  const weeklyPositionsCreatedWith30 = (weeklyPositions && weeklyPositions[30]) || 0;

  const notCustomPositions = fourHoursPositionsCreatedWith7 +
    dailyPositionsCreatedWith7 +
    weeklyPositionsCreatedWith7 +
    fourHoursPositionsCreatedWith15 +
    dailyPositionsCreatedWith15 +
    weeklyPositionsCreatedWith15 +
    fourHoursPositionsCreatedWith30 +
    dailyPositionsCreatedWith30 +
    weeklyPositionsCreatedWith30;

  const customPositions = positions.length - notCustomPositions;

  const customFourHoursSwapsKeys = Object.values(fourHourPositions).filter(swaps => swaps !== 7 && swaps !== 15 && swaps !== 30);
  const customDailySwapsKeys = Object.values(dailyPositions).filter(swaps => swaps !== 7 && swaps !== 15 && swaps !== 30);
  const customWeeklySwapsKeys = Object.values(weeklyPositions).filter(swaps => swaps !== 7 && swaps !== 15 && swaps !== 30);

  const customFourHoursAverageSwaps = customFourHoursSwapsKeys.reduce((acc, swaps) => acc + swaps, 0) / customFourHoursSwapsKeys.length;
  const customDailyAverageSwaps = customDailySwapsKeys.reduce((acc, swaps) => acc + swaps, 0) / customDailySwapsKeys.length;
  const customWeeklyAverageSwaps = customWeeklySwapsKeys.reduce((acc, swaps) => acc + swaps, 0) / customWeeklySwapsKeys.length;

  return (
    <>
      <Grid container alignItems="center" justifyItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Get volume tracked by tokens</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={onCheckVolume}>
                  Check Positions
                </Button>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper alternativeLabel activeStep={currentStep} connector={<QontoConnector />}>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {currentStep === index ? label.current : currentStep > index ? label.done : label.pending}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
          <StyledPaper>
            <Typography variant="h6">
              Positions:
            </Typography>
            <Typography variant="body1">
              {positions.length}
            </Typography>
            <Typography variant="h6">
              Custom time positions:
            </Typography>
            <Typography variant="body1">
              {customPositions} ({((customPositions/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
          <StyledPaper>
            <Typography variant="h6">
              Custom Four hours average swaps:
            </Typography>
            <Typography variant="body1">
              {customFourHoursAverageSwaps}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
        <StyledPaper>
            <Typography variant="h6">
              Custom daily average swaps:
            </Typography>
            <Typography variant="body1">
              {customDailyAverageSwaps}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={3}>
        <StyledPaper>
            <Typography variant="h6">
              Custom weekly average swaps:
            </Typography>
            <Typography variant="body1">
              {customWeeklyAverageSwaps}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Four Hours 7 positions:
            </Typography>
            <Typography variant="body1">
              {fourHoursPositionsCreatedWith7} ({((fourHoursPositionsCreatedWith7/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Four Hours 15 positions:
            </Typography>
            <Typography variant="body1">
              {fourHoursPositionsCreatedWith15} ({((fourHoursPositionsCreatedWith15/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Four Hours 30 positions:
            </Typography>
            <Typography variant="body1">
              {fourHoursPositionsCreatedWith30} ({((fourHoursPositionsCreatedWith30/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Daily 7 positions:
            </Typography>
            <Typography variant="body1">
              {dailyPositionsCreatedWith7} ({((dailyPositionsCreatedWith7/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Daily 15 positions:
            </Typography>
            <Typography variant="body1">
              {dailyPositionsCreatedWith15} ({((dailyPositionsCreatedWith15/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Daily 30 positions:
            </Typography>
            <Typography variant="body1">
              {dailyPositionsCreatedWith30} ({((dailyPositionsCreatedWith30/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Weekly 7 positions:
            </Typography>
            <Typography variant="body1">
              {weeklyPositionsCreatedWith7} ({((weeklyPositionsCreatedWith7/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Weekly 15 positions:
            </Typography>
            <Typography variant="body1">
              {weeklyPositionsCreatedWith15} ({((weeklyPositionsCreatedWith15/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            <Typography variant="h6">
              Weekly 30 positions:
            </Typography>
            <Typography variant="body1">
              {weeklyPositionsCreatedWith30} ({((weeklyPositionsCreatedWith30/positions.length) * 100).toFixed(2)}%)
            </Typography>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
};

export default PositionsAnalyticsAdmin;
