import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { HUB_ADDRESS, SWAP_INTERVALS } from 'config/constants';
import HubAbi from 'abis/Hub.json';
import CenteredLoadingIndicator from 'common/centered-loading-indicator';
import { buildEtherscanTransaction } from 'utils/etherscan';
import { erc20ABI, useAccount, useNetwork, usePublicClient, useWalletClient } from 'wagmi';
import { Address, getContract, maxUint256, parseUnits } from 'viem';

const PositionCreationAdmin = () => {
  const { address, connector } = useAccount();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const [approvedIntervals, setApprovedIntervals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [from, setFrom] = useState<Address>('0x');
  const [fromInfo, setFromInfo] = useState({
    name: '',
    symbol: '',
    address: '',
    decimals: 18,
    loading: false,
  });
  const [toInfo, setToInfo] = useState({
    name: '',
    symbol: '',
    address: '',
    decimals: 18,
    loading: false,
  });
  const [to, setTo] = useState<Address>('0x');
  const [rate, setRate] = useState('');
  const [interval, setInterval] = useState('');
  const [swaps, setSwaps] = useState('');

  useEffect(() => {
    if (!connector?.getProvider()) {
      console.warn('no library detected');
      return;
    }

    const getIntervals = async () => {
      if (!walletClient) {
        return;
      }

      const HubContract = getContract({
        address: HUB_ADDRESS[chain?.id || 10],
        abi: HubAbi.abi,
        publicClient,
        walletClient,
      });

      const allowedIntervals = await HubContract.read.allowedSwapIntervals();

      setApprovedIntervals(allowedIntervals as number);
      setIsLoading(false);
    };

    getIntervals();
  }, [connector]);

  const isIntervalApproved = (value: number) => {
    return (approvedIntervals & value) !== 0;
  };

  const onVerifyFrom = () => {
    if (!walletClient) {
      return;
    }

    const erc20 = getContract({
      address: from,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    setFromInfo({
      ...fromInfo,
      loading: true,
    });

    Promise.all([erc20.read.name(), erc20.read.decimals(), erc20.read.symbol()]).then(([name, decimals, symbol]) => {
      setFromInfo({
        name,
        decimals,
        symbol,
        address: from,
        loading: false,
      });
    });
  };

  const onVerifyTo = () => {
    if (!walletClient) {
      return;
    }

    const erc20 = getContract({
      address: to,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    setToInfo({
      ...toInfo,
      loading: true,
    });

    Promise.all([erc20.read.name(), erc20.read.decimals(), erc20.read.symbol()]).then(([name, decimals, symbol]) => {
      setToInfo({
        name,
        decimals,
        address: to,
        symbol,
        loading: false,
      });
    });
  };

  const approveToken = async () => {
    if (!walletClient) {
      return;
    }

    const erc20 = getContract({
      address: from,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await erc20.write.approve([HUB_ADDRESS[chain?.id || 10], maxUint256]);
      window.open(buildEtherscanTransaction(contractResponse || '', chain?.id || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const createPosition = async () => {
    if (!walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chain?.id || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });
    const totalAmmount = parseUnits(rate, fromInfo.decimals) * BigInt(swaps);
    try {
      const contractResponse = await HubContract.write.deposit([
        from,
        to,
        totalAmmount,
        BigInt(swaps),
        BigInt(interval),
        address,
        [],
      ]);
      window.open(buildEtherscanTransaction(contractResponse || '', chain?.id || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    return <CenteredLoadingIndicator />;
  }

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">Create a position</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          id="from"
          label="From"
          onChange={(event) => setFrom(event.target.value as Address)}
          value={from}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <Button onClick={onVerifyFrom}>Verify</Button>
          </Grid>
          <Grid item xs={9}>
            {fromInfo.loading && <CenteredLoadingIndicator />}
            {!fromInfo.loading && (
              <Grid container direction="column">
                <Grid item xs={4}>
                  <Typography variant="body1">Name: {fromInfo.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">Symbol: {fromInfo.symbol}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">Decimals: {fromInfo.decimals}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          id="to"
          label="To"
          onChange={(event) => setTo(event.target.value as Address)}
          value={to}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <Button onClick={onVerifyTo}>Verify</Button>
          </Grid>
          <Grid item xs={9}>
            {toInfo.loading && <CenteredLoadingIndicator />}
            {!toInfo.loading && (
              <Grid container direction="column">
                <Grid item xs={4}>
                  <Typography variant="body1">Name: {toInfo.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">Symbol: {toInfo.symbol}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">Decimals: {toInfo.decimals}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="rate"
          label="Rate"
          onChange={(event) => setRate(event.target.value)}
          value={rate}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="interval"
          select
          label="Interval"
          helperText=" "
          value={interval}
          onChange={(event) => setInterval(event.target.value)}
        >
          {SWAP_INTERVALS.filter((swapInterval) => isIntervalApproved(swapInterval.key)).map((swapInterval) => (
            <MenuItem key={swapInterval.key} value={swapInterval.value}>
              {swapInterval.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="swaps"
          label="Ammount of swaps"
          onChange={(event) => setSwaps(event.target.value)}
          value={swaps}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          onClick={() => {
            onVerifyFrom();
            onVerifyTo();
          }}
        >
          Verify tokens
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          onClick={approveToken}
          disabled={fromInfo.name === '' || toInfo.name === '' || fromInfo.address !== from || toInfo.address !== to}
        >
          Approve from
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          onClick={createPosition}
          disabled={fromInfo.name === '' || toInfo.name === '' || fromInfo.address !== from || toInfo.address !== to}
        >
          Create position
        </Button>
      </Grid>
    </Grid>
  );
};
export default PositionCreationAdmin;
