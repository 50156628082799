import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import find from 'lodash/find';
import TextField from '@mui/material/TextField';
import ERC20ABI from 'abis/erc20.json';
import { AllowedToken, GetTokenListData, Nullable } from 'types';
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import getTokenList from 'graphql/getTokenList.graphql';
import {
  CHAINLINK_ORACLE_ADDRESS,
  COINGECKO_IDS,
  DEFILLAMA_IDS,
  UNISWAP_ORACLE_ADDRESS,
  ZERO_EX_API_URL,
} from 'config/constants';
import axios from 'axios';
import { UNI_GRAPHQL_URL } from 'config/constants';
import getPairLiquidity from 'graphql/getPairLiquidity.graphql';
import { DAI_ADDRESSES, getWrappedProtocolToken, USDC_ADDRESSES } from 'config/tokens';
import { sortTokens } from 'utils/currency';
import gqlFetchAll from 'utils/gqlFetchAll';
import ChainlinkAbi from 'abis/ChainlinkOracle.json';
import UniswapAbi from 'abis/UniswapOracle.json';
import CenteredLoadingIndicator from 'common/centered-loading-indicator';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from '@mui/material';
import { buildEtherscanToken } from 'utils/etherscan';
import { Address, parseUnits, getContract } from 'viem';
import { useWalletClient, usePublicClient, useNetwork, erc20ABI } from 'wagmi';

export const createClient = (chainId: number) =>
  new ApolloClient({
    uri: UNI_GRAPHQL_URL[chainId],
    cache: new InMemoryCache(),
  });

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
`;

interface TokenBaseData {
  name: string;
  decimals: number;
  symbol: string;
  totalSupply: BigInt;
}

// https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000042
interface EtherscanData {
  holders: number;
  transfers: number;
  volume: number;
}

interface UniswapData {
  pools?: number;
  tvl?: string;
  volumeUSD?: string;
  name: string;
}

interface OracleData {
  name: string;
  chainlink: boolean;
  uniswap: boolean;
}

interface AllowlistData {
  hasSameName?: AllowedToken;
  hasSameSymbol?: AllowedToken;
  symbolAndNameEqual: boolean;
  sameDecimals: boolean;
}

interface ZeroExData {
  hundredSwap?: {
    price: string;
    guaranteedPrice: string;
    priceImpact: string;
  };
  thousandSwap?: {
    price: string;
    guaranteedPrice: string;
    priceImpact: string;
  };
  tenThousandSwap?: {
    price: string;
    guaranteedPrice: string;
    priceImpact: string;
  };
}

interface DefillamaData {
  supported: boolean;
  price: number;
}

interface CoingeckoData {
  supported: boolean;
  price: number;
}

// Is it official?

// Check if recognized by Coingecko/DefiLlama

// Check amount of holders

// Check transfers / volume

// Check if other already whitelisted with same name/symbol

// Oracle

// If Chainlink supports it, then ok

// If not, does Univ3 support it? If not, then fail

// If it supports it, then does it have enough liquidity?

// If so, is the price ok according to Coingecko (or other)?

const TokenChecklistAdmin = () => {
  const [token, setToken] = useState<Address>('0x');
  const [isLoadingTokenData, setIsLoadingTokenData] = useState(false);
  const [hasFetchedValues, setHasFetchedValues] = useState(false);
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const [tokenBaseData, setTokenBaseData] = useState<Nullable<TokenBaseData>>(null);
  const [defillamaData, setDefillamaData] = useState<Nullable<DefillamaData>>(null);
  const [coingeckoData, setCoingeckoData] = useState<Nullable<CoingeckoData>>(null);
  const [etherscanData, setEtherscanData] = useState<Nullable<EtherscanData>>(null);
  const [allowlistData, setAllowlistData] = useState<Nullable<AllowlistData>>(null);
  const [zeroExData, setZeroExData] = useState<Nullable<ZeroExData>>(null);
  const [uniswapData, setUniswapData] = useState<Nullable<UniswapData[]>>(null);
  const [oracleData, setOracleData] = useState<Nullable<OracleData[]>>(null);
  const { loading: isLoadingTokenList, data: allowlistTokensData } = useQuery<GetTokenListData>(getTokenList);
  const wrappedProtocolToken = getWrappedProtocolToken(chainId || 10);

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }
  }, [walletClient]);

  const getTokenBaseData = async () => {
    if (!walletClient) {
      return;
    }
    try {
      const erc20 = getContract({
        address: token,
        abi: erc20ABI,
        publicClient,
        walletClient,
      });
      const tokenName = await erc20.read.name();
      const tokenDecimals = await erc20.read.decimals();
      const tokenSymbol = await erc20.read.symbol();
      const totalSupply = await erc20.read.totalSupply();
      const tokenData = {
        name: tokenName,
        decimals: tokenDecimals,
        totalSupply,
        symbol: tokenSymbol,
      };
      setTokenBaseData(tokenData);
      getAllowlistData(tokenData);
    } catch (e) {
      console.error('Error fetching data from token contract', e);
      setTokenBaseData(null);
    }
  };

  const getZeroExData = async () => {
    if (!chainId) {
      return;
    }
    try {
      const priceForHundred = await axios.get<{ estimatedPriceImpact: string; guaranteedPrice: string; price: string }>(
        `${ZERO_EX_API_URL[chainId]}swap/v1/quote?buyToken=${token}&sellToken=${
          USDC_ADDRESSES[chainId || 10]
        }&sellAmount=${parseUnits('100', 6)}`
      );
      const priceForThousand = await axios.get<{
        estimatedPriceImpact: string;
        guaranteedPrice: string;
        price: string;
      }>(
        `${ZERO_EX_API_URL[chainId]}swap/v1/quote?buyToken=${token}&sellToken=${
          USDC_ADDRESSES[chainId || 10]
        }&sellAmount=${parseUnits('1000', 6)}`
      );
      const priceForTenThousand = await axios.get<{
        estimatedPriceImpact: string;
        guaranteedPrice: string;
        price: string;
      }>(
        `${ZERO_EX_API_URL[chainId]}swap/v1/quote?buyToken=${token}&sellToken=${
          USDC_ADDRESSES[chainId || 10]
        }&sellAmount=${parseUnits('10000', 6)}`
      );
      setZeroExData({
        hundredSwap: {
          price: priceForHundred.data.price,
          guaranteedPrice: priceForHundred.data.guaranteedPrice,
          priceImpact: priceForHundred.data.estimatedPriceImpact,
        },
        thousandSwap: {
          price: priceForThousand.data.price,
          guaranteedPrice: priceForThousand.data.guaranteedPrice,
          priceImpact: priceForThousand.data.estimatedPriceImpact,
        },
        tenThousandSwap: {
          price: priceForTenThousand.data.price,
          guaranteedPrice: priceForTenThousand.data.guaranteedPrice,
          priceImpact: priceForTenThousand.data.estimatedPriceImpact,
        },
      });
    } catch (e) {
      console.error('Error fetching data from 0x', e);
      setZeroExData(null);
    }
  };

  const getAllowlistData = async (tokenData: TokenBaseData) => {
    if (!allowlistTokensData?.tokens || !tokenData) {
      return;
    }

    const hasSameName = find(allowlistTokensData.tokens, { name: tokenData.name });
    const hasSameSymbol = find(allowlistTokensData.tokens, { symbol: tokenData.symbol });

    setAllowlistData({
      hasSameName,
      hasSameSymbol,
      symbolAndNameEqual: hasSameName?.address === hasSameSymbol?.address,
      sameDecimals: hasSameName?.decimals === tokenData.decimals,
    });
  };

  const getDefillamaData = async () => {
    if (!chainId) {
      return;
    }
    try {
      const price = await axios.post<{ coins: Record<string, { price: number }> }>('https://coins.llama.fi/prices', {
        coins: [`${DEFILLAMA_IDS[chainId]}:${token}`],
      });
      const tokenPrice = price.data.coins[`${DEFILLAMA_IDS[chainId]}:${token}`].price;

      setDefillamaData({
        supported: true,
        price: tokenPrice,
      });
    } catch (e) {
      console.error('Error fetching data from defillama', e);
      setDefillamaData(null);
    }
  };

  const getCoingeckoData = async () => {
    if (!chainId) {
      return;
    }
    try {
      const price = await axios.get<Record<string, { usd: number }>>(
        `https://api.coingecko.com/api/v3/simple/token_price/${COINGECKO_IDS[chainId]}?contract_addresses=${token}&vs_currencies=usd`
      );

      const tokenPrice = price.data[token].usd;

      setCoingeckoData({
        supported: true,
        price: tokenPrice,
      });
    } catch (e) {
      console.error('Error fetching data from coingecko', e);
      setCoingeckoData(null);
    }
  };

  const getUniswapData = async () => {
    const getUniTokenData = async (tokenToCheckAgaints: string, name: string): Promise<UniswapData> => {
      try {
        const [tokenA, tokenB] = sortTokens(tokenToCheckAgaints, token);
        const uniClient = createClient(chainId || 10);

        const { data: uniswapGqlData, loading } = await gqlFetchAll<{
          pools: {
            id: string;
            token0Price: string;
            token1Price: string;
            volumeUSD: string;
            totalValueLockedUSD: string;
          }[];
        }>(uniClient, getPairLiquidity, { tokenA, tokenB }, 'pools');

        const pools = uniswapGqlData?.pools;
        return {
          pools: pools?.length,
          tvl: pools?.reduce((acc, pool) => acc + parseFloat(pool.totalValueLockedUSD), 0).toString(),
          volumeUSD: pools?.reduce((acc, pool) => acc + parseFloat(pool.volumeUSD), 0).toString(),
          name,
        };
      } catch (e) {
        console.error('Error fetching data from uniswap', e);
        return {
          name,
        };
      }
    };
    const promises = [];

    promises.push(getUniTokenData(wrappedProtocolToken.address, wrappedProtocolToken.name));
    promises.push(getUniTokenData(USDC_ADDRESSES[chainId || 10], 'USDC'));
    promises.push(getUniTokenData(DAI_ADDRESSES[chainId || 10], 'DAI'));

    const promiseResults = await Promise.all(promises);

    setUniswapData(promiseResults);
  };

  const getOracleData = async () => {
    if (!chainId) {
      return;
    }
    const getChainlinkTokenData = async (tokenToCheckAgaints: string, name: string): Promise<OracleData> => {
      if (!walletClient) {
        return {
          chainlink: false,
          uniswap: false,
          name,
        };
      }

      try {
        const ChainlinkOracleContract = getContract({
          address: CHAINLINK_ORACLE_ADDRESS[chainId || 10],
          abi: ChainlinkAbi.abi,
          publicClient,
          walletClient,
        });
        const UniswapOracleContract = getContract({
          address: UNISWAP_ORACLE_ADDRESS[chainId || 10],
          abi: UniswapAbi.abi,
          publicClient,
          walletClient,
        });
        const [tokenA, tokenB] = sortTokens(tokenToCheckAgaints, token);

        const isChainlinkAllowed = (await ChainlinkOracleContract.read.canSupportPair([tokenA, tokenB])) as boolean;
        const isUniswapAllowed = (await UniswapOracleContract.read.canSupportPair([tokenA, tokenB])) as boolean;

        return {
          name,
          chainlink: isChainlinkAllowed,
          uniswap: isUniswapAllowed,
        };
      } catch (e) {
        console.error('Error fetching data from chainlink', e);
        return {
          chainlink: false,
          uniswap: false,
          name,
        };
      }
    };
    const promises = [];

    promises.push(getChainlinkTokenData(wrappedProtocolToken.address, wrappedProtocolToken.name));
    promises.push(getChainlinkTokenData(USDC_ADDRESSES[chainId || 10], 'USDC'));
    promises.push(getChainlinkTokenData(DAI_ADDRESSES[chainId || 10], 'DAI'));

    const promiseResults = await Promise.all(promises);

    setOracleData(promiseResults);
  };

  const onCheckToken = async () => {
    setIsLoadingTokenData(true);
    await Promise.all([
      // fetch token base data
      getTokenBaseData(),
      // fetch defillama data
      getDefillamaData(),
      // fetch coingecko data
      getCoingeckoData(),
      // fetch etherscan data
      // getEtherscanData(),
      // fetch uniswap data
      getUniswapData(),
      // fetch oracle data
      getOracleData(),
      // fetch zero ex data,
      getZeroExData(),
    ]);

    setIsLoadingTokenData(false);
    if (!hasFetchedValues) {
      setHasFetchedValues(true);
    }
  };

  console.log('tokenBaseData:', tokenBaseData);
  console.log('defillamaData:', defillamaData);
  console.log('coingeckoData:', coingeckoData);
  console.log('etherscanData:', etherscanData);
  console.log('zeroExData:', zeroExData);
  console.log('uniswapData:', uniswapData);
  console.log('oracleData:', oracleData);
  console.log('allowlistData:', allowlistData);

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">Checklist to allow token</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="Token"
                label="Token"
                onChange={(event) => setToken(event.target.value as Address)}
                value={token}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={onCheckToken}>
                Check token
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      {isLoadingTokenData && (
        <Grid item xs={12}>
          <CenteredLoadingIndicator />
        </Grid>
      )}

      {hasFetchedValues && !isLoadingTokenData && (
        <>
          <Grid item xs={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5">Token details</Typography>
                {tokenBaseData && (
                  <>
                    <Typography variant="body1">Name: {tokenBaseData.name}</Typography>
                    <Typography variant="body1">Symbol: {tokenBaseData.symbol}</Typography>
                    <Typography variant="body1">Decimals: {tokenBaseData.decimals}</Typography>
                    <Typography variant="body1">Total supply: {tokenBaseData.totalSupply.toString()}</Typography>
                    <Typography variant="body1">
                      <Link target="_blank" href={buildEtherscanToken(token, chainId || 10)}>
                        Explorer link
                      </Link>
                    </Typography>
                    {allowlistData && (
                      <>
                        <Typography variant="body2">
                          Found token with same name?: {!!allowlistData.hasSameName?.toString()} (address:{' '}
                          {allowlistData.hasSameName?.address})
                        </Typography>
                        <Typography variant="body2">
                          Found token with same symbol?: {!!allowlistData.hasSameSymbol?.toString()} (address:{' '}
                          {allowlistData.hasSameSymbol?.address})
                        </Typography>
                        <Typography variant="body2">
                          Found token has same decimals?: {!!allowlistData.sameDecimals?.toString()}
                        </Typography>
                      </>
                    )}
                  </>
                )}
                {!tokenBaseData && <Typography variant="body1">Could not fetch token data from contract</Typography>}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5">Defillama data</Typography>
                {defillamaData && (
                  <>
                    <Typography variant="body1">Is supported: {defillamaData.supported?.toString()}</Typography>
                    <Typography variant="body1">Price: {defillamaData.price}</Typography>
                  </>
                )}
                {!defillamaData && (
                  <>
                    <Typography variant="body1">
                      Could not fetch defillama data (probably not supported, check network tab)
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5">Coingecko data</Typography>
                {coingeckoData && (
                  <>
                    <Typography variant="body1">Is supported: {coingeckoData.supported?.toString()}</Typography>
                    <Typography variant="body1">Price: {coingeckoData.price}</Typography>
                  </>
                )}
                {!coingeckoData && (
                  <>
                    <Typography variant="body1">
                      Could not fetch coingeckoData data (probably not supported, check network tab)
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} />
          {!zeroExData && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    Could not fetch 0x data (check network tab something got rekt)
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {zeroExData && (
            <>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5">0x data</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5">100 swap</Typography>
                    {defillamaData && tokenBaseData && (
                      <Typography variant="body1">
                        Price difference vs defillama:{' '}
                        {(parseFloat(zeroExData.hundredSwap?.price || '0') * defillamaData.price - 1) * 100}%
                      </Typography>
                    )}
                    {coingeckoData && tokenBaseData && (
                      <Typography variant="body1">
                        Price difference vs coingecko:{' '}
                        {(parseFloat(zeroExData.hundredSwap?.price || '0') * coingeckoData.price - 1) * 100}%
                      </Typography>
                    )}
                    <Typography variant="body1">Price: {zeroExData.hundredSwap?.price}</Typography>
                    <Typography variant="body1">
                      Price USD: {1 / parseFloat(zeroExData.hundredSwap?.price || '0')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5">1000 swap</Typography>
                    {defillamaData && tokenBaseData && (
                      <Typography variant="body1">
                        Price difference vs defillama:{' '}
                        {(parseFloat(zeroExData.thousandSwap?.price || '0') * defillamaData.price - 1) * 100}%
                      </Typography>
                    )}
                    {coingeckoData && tokenBaseData && (
                      <Typography variant="body1">
                        Price difference vs coingecko:{' '}
                        {(parseFloat(zeroExData.thousandSwap?.price || '0') * coingeckoData.price - 1) * 100}%
                      </Typography>
                    )}

                    <Typography variant="body1">Price: {zeroExData.thousandSwap?.price}</Typography>
                    <Typography variant="body1">
                      Price USD: {1 / parseFloat(zeroExData.thousandSwap?.price || '0')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5">10000 swap</Typography>
                    {defillamaData && tokenBaseData && (
                      <Typography variant="body1">
                        Price difference vs defillama:{' '}
                        {(parseFloat(zeroExData.tenThousandSwap?.price || '0') * defillamaData.price - 1) * 100}%
                      </Typography>
                    )}
                    {coingeckoData && tokenBaseData && (
                      <Typography variant="body1">
                        Price difference vs coingecko:{' '}
                        {(parseFloat(zeroExData.tenThousandSwap?.price || '0') * coingeckoData.price - 1) * 100}%
                      </Typography>
                    )}
                    <Typography variant="body1">Price: {zeroExData.tenThousandSwap?.price}</Typography>
                    <Typography variant="body1">
                      Price USD: {1 / parseFloat(zeroExData.tenThousandSwap?.price || '0')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
          <Grid item xs={12} />
          {!uniswapData && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    Could not fetch uniswap data (check network tab something got rekt)
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {uniswapData && (
            <>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5">Uniswap data</Typography>
                  </CardContent>
                </Card>
              </Grid>
              {uniswapData?.map((poolData) => (
                <Grid item xs={3}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h5">Pool: {poolData.name}</Typography>
                      <Typography variant="body1">Data grabbed from: {poolData.pools} pools</Typography>
                      <Typography variant="body1">TVL: {poolData.tvl}</Typography>
                      <Typography variant="body1">Volume USD: {poolData.volumeUSD}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12} />
          {!oracleData && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1">
                    Could not fetch oracle data (check console tag, something got rekt)
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {oracleData && (
            <>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5">Oracle data</Typography>
                  </CardContent>
                </Card>
              </Grid>
              {oracleData.map((poolData) => (
                <Grid item xs={3}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h5">Paired with: {poolData.name}</Typography>
                      <Typography variant="body1">Supported by Chainlink: {poolData.chainlink.toString()}</Typography>
                      <Typography variant="body1">Supported by uniswap: {poolData.uniswap.toString()}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

//0x68f180fcce6836688e9084f035309e29bf0a2095
export default TokenChecklistAdmin;
