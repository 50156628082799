import gql from 'graphql-tag';

const getTokenList = gql`
  query getTokenList {
    tokens(where: { allowed: true }, subgraphError: "allow") {
      address: id
      allowed
      decimals
      name
      symbol
      type
    }
  }
`;

export default getTokenList;
