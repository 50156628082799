import gql from 'graphql-tag';
import { TokenType, Token } from 'types';

export interface FetchedToken {
  address: string;
  allowed: boolean;
  decimals: number;
  name: string;
  symbol: string;
  type: TokenType;
  underlyingTokens: Token[];
}

export interface GetAllTokensResponseData {
  tokens: FetchedToken[];
}

const getAllTokens = gql`
  query getAllTokens($first: Int, $lastId: String) {
    tokens(first: $first, where: { id_gt: $lastId }, subgraphError: "allow") {
      address: id
      allowed
      decimals
      name
      symbol
      type
      underlyingTokens {
        address: id
        allowed
        decimals
        name
        symbol
        type
      }
    }
  }
`;

export default getAllTokens;
