import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { MEAN_GRAPHQL_URL, PositionVersions } from 'config/constants';

export const createClient = ({ chainId, version }: { chainId: number; version: PositionVersions }) =>
  new ApolloClient({
    uri: MEAN_GRAPHQL_URL[version][chainId],
    cache: new InMemoryCache(),
  });

const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/storres93/mean-kovan',
  cache: new InMemoryCache(),
});

export default client;
