import gql from 'graphql-tag';

export interface Position {
  id: string;
  swapInterval: {
    id: string;
    interval: string;
  };
  history: {
    id: string;
    action: string;
    remainingSwaps: string;
  }[]
}
export interface GetPositionsResponseData {
  positions: Position[];
}

export const getPositions = gql`
  query getPositions($first: Int, $lastId: String) {
    positions(first: $first, where: { id_gt: $lastId }, subgraphError: "allow") {
      id,
      swapInterval {
        id
        interval
      }
      history(
        where: { action: CREATED }
        subgraphError: "allow"
      ) {
        id
        action
        transaction {
          id
          hash
          timestamp
          gasPrice
          l1GasPrice
          overhead
        }
        createdAtBlock
        createdAtTimestamp

        ... on CreatedAction {
          rate
          remainingSwaps
          rateUnderlying
          permissions {
            operator
            id
            permissions
          }
        }
      }
    }
  }
`;
