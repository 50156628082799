import gql from 'graphql-tag';

const getPairs = gql`
  query getPairs {
    pairs(first: 1000, subgraphError: "allow") {
      id
      tokenA {
        address: id
        symbol
        name
        decimals
      }
      tokenB {
        address: id
        symbol
        name
        decimals
      }
      createdAtTimestamp
    }
  }
`;

export default getPairs;
