import React, { CSSProperties, useCallback, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles, withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
// import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Search from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// import Chip from '@mui/material/Chip';
import TokenIcon from 'common/token-icon';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FilledInput from '@mui/material/FilledInput';
import { Button, Chip, createStyles, Switch, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { DCAToken, DCATokenLists } from 'hooks/useDCATokenLists';
import { findIndex, isEqual, find, uniq } from 'lodash';
import Modal from 'common/modal';
import { TokenType } from 'types';
import ComposedTokenIcon from 'common/composed-token-icon';
import { getChainByKey } from '@mean-finance/sdk';
import { toToken } from 'utils/currency';
import { PositionVersions } from 'config/constants';

type SetFromToState = React.Dispatch<React.SetStateAction<DCAToken[]>>;

const StyledOverlay = styled.div`
  background-color: #1b1b1c;
  display: flex;
  align-self: stretch;
  flex: 1;
  text-align: start;
`;

const StyledSwitchGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.5) !important;
  flex: 0 !important;
`;

const StyledFilledInput = withStyles(FilledInput, () =>
  createStyles({
    root: {
      borderRadius: '8px',
    },
    input: {
      paddingTop: '8px',
    },
  })
);

// const StyledChip = styled(Chip)`
//   margin-right: 5px;
// `;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 0px;
  margin-right: 7px;
  cursor: pointer;
`;

const StyledPasteIcon = styled(ContentPasteIcon)`
  cursor: pointer;
`;

const StyledListItem = styled(ListItem)`
  padding-left: 0px;
`;

const StyledList = styled(List)`
  ${({ theme }) => `
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    --scrollbarBG: #424242;
    --thumbBG: #ffffff;
    ::-webkit-scrollbar {
      width: 11px;
    }
    ::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 6px;
      border: 3px solid var(--scrollbarBG);
    }
  `}
`;

const StyledDialogTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
`;

const StyledGrid = styled(Grid)<{ customSpacing?: number }>`
  margin-top: ${(props) => props.customSpacing || 0}px;
`;

const StyledBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTokenTextContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const StyledEndAdormentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const StyledVersionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface TokenPickerToken extends DCAToken {
  versions: PositionVersions[];
  chainIds: number[];
  selected: boolean;
  tokens: (TokenPickerToken | DCAToken)[];
}

interface RowData {
  tokenList: TokenPickerToken[];
  onClick: (token: TokenPickerToken) => void;
}

interface RowProps {
  index: number;
  style: CSSProperties;
  data: RowData;
}

interface EmptyRowProps {
  style: CSSProperties;
}

interface TokenPickerProps {
  isOpen?: boolean;
  onChange: SetFromToState;
  onClose: () => void;
  tokens: DCATokenLists;
}

export const getGhTokenListLogoUrl = (chainId: number, address: string) =>
  `https://raw.githubusercontent.com/Mean-Finance/token-list/main/assets/chains/${chainId}/${address.toLowerCase()}.svg`;

const useListItemStyles = makeStyles()(({ palette }) => ({
  root: {
    borderRadius: 6,
    color: palette.text.secondary,
    cursor: 'pointer',
    '&:hover': {
      color: palette.text.primary,
      backgroundColor: palette.mode === 'light' ? palette.grey[100] : palette.grey[700],
    },
  },
  selected: {
    '&.Mui-selected': {
      fontWeight: 500,
      // backgroundColor: palette.primary.mainaccentColor,
      color: palette.primary.main,
      '&:hover': {
        color: palette.primary.main,
        // backgroundColor: accentColor
      },
    },
  },
}));

const Row = ({ index, style, data: { onClick, tokenList } }: RowProps) => {
  const classes = useListItemStyles();
  const token = tokenList[index];

  if (!token) {
    return null;
  }

  return (
    <StyledListItem classes={classes.classes} onClick={() => onClick(token)} style={style}>
      <StyledListItemIcon>
        <TokenIcon size="24px" token={token} />
      </StyledListItemIcon>
      <ListItemText disableTypography>
        <StyledTokenTextContainer>
          <Typography variant="body1" component="span" color="#FFFFFF">
            {token.name}
          </Typography>
          <Typography variant="body1" component="span" color="rgba(255, 255, 255, 0.5)">
            {` (${token.symbol})`}
          </Typography>
        </StyledTokenTextContainer>
      </ListItemText>
      <StyledBalanceContainer>
        <StyledVersionsContainer>
          {token.versions.map((version) => (
            <Chip label={version} size="small" />
          ))}
        </StyledVersionsContainer>
        <StyledVersionsContainer>
          {token.chainIds.map((chainId) => {
            const network = getChainByKey(chainId)!;

            const networkToken = toToken({
              address: network.wToken,
              chainId: network.chainId,
              logoURI: getGhTokenListLogoUrl(network.chainId, 'logo'),
            });
            return <TokenIcon token={networkToken} size="14px" />;
          })}
        </StyledVersionsContainer>
      </StyledBalanceContainer>
    </StyledListItem>
  );
};

const TokenPicker = ({ onClose, onChange, isOpen, tokens }: TokenPickerProps) => {
  const searchInputRef = React.useRef<HTMLElement>();
  const [search, setSearch] = React.useState('');
  const [selectedTokens, setSelectedTokens] = useState<DCAToken[]>([]);
  const [groupByChain, setGroupByChain] = useState(true);
  const [groupByVersion, setGroupByVersion] = useState(true);
  const [showYieldTokens, setShowYieldTokens] = useState(false);

  React.useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const handleOnClose = useCallback(() => {
    setSearch('');
    onClose();
  }, []);

  const handleConfirm = useCallback(() => {
    onChange(selectedTokens);
    setSearch('');
    handleOnClose();
  }, [handleOnClose, selectedTokens]);

  const handleItemSelected = (token: TokenPickerToken) => {
    const foundIndex = findIndex(selectedTokens, {
      address: token.address,
      chainId: token.chainId,
      version: token.version,
    });
    if (foundIndex === -1) {
      setSelectedTokens((prevSelected) => [...prevSelected, ...token.tokens]);
    } else {
      setSelectedTokens((prevSelected) =>
        prevSelected.filter(
          (prevToken) => !token.tokens.filter((tokenToFilter) => !isEqual(prevToken, tokenToFilter)).length
        )
      );
    }
  };

  const handleDeselect = (token: DCAToken) => {
    const foundIndex = findIndex(selectedTokens, {
      address: token.address,
      chainId: token.chainId,
      version: token.version,
    });
    if (foundIndex === -1) {
      return;
    } else {
      setSelectedTokens((prevSelected) =>
        prevSelected.filter(
          (prevToken) =>
            token.symbol !== prevToken.symbol ||
            token.chainId !== prevToken.chainId ||
            token.version !== prevToken.version ||
            token.address !== prevToken.address ||
            token.chainId !== prevToken.chainId
        )
      );
    }
  };

  const onPasteAddress = async () => {
    const value = await navigator.clipboard.readText();

    setSearch(value);
  };

  const tokenList = React.useMemo(() => {
    const filteredAndSorted = tokens
      .filter(
        (token) =>
          (token.name.toLowerCase().includes(search.toLowerCase()) ||
            token.symbol.toLowerCase().includes(search.toLowerCase()) ||
            token.address.toLowerCase().includes(search.toLowerCase())) &&
          (showYieldTokens || token.type !== TokenType.Yield)
      )
      .sort();

    let result = filteredAndSorted.map<TokenPickerToken>((token) => {
      const selected = find(selectedTokens, { address: token.address, chainId: token.chainId, version: token.version });

      const newToken: TokenPickerToken = {
        ...token,
        selected: !!selected,
        versions: [token.version],
        chainIds: [token.chainId],
        tokens: [token],
      };
      return newToken;
    });

    if (groupByChain) {
      result = result.reduce<TokenPickerToken[]>((acc, token) => {
        const newAcc = [...acc];

        const foundIndex = findIndex(
          newAcc,
          (tokenToFind) => tokenToFind.symbol === token.symbol && tokenToFind.versions.includes(token.version)
        );

        if (foundIndex !== -1) {
          if (!newAcc[foundIndex].chainIds.includes(token.chainId)) {
            newAcc[foundIndex].chainIds = uniq([...token.chainIds, ...newAcc[foundIndex].chainIds]);
            newAcc[foundIndex].tokens = uniq([...token.tokens, ...newAcc[foundIndex].tokens]);
          }

          if (!newAcc[foundIndex].logoURI && token.logoURI) {
            newAcc[foundIndex].logoURI = token.logoURI;
          }
        } else {
          newAcc.push(token);
        }
        return newAcc;
      }, []);
    }

    if (groupByVersion) {
      result = result.reduce<TokenPickerToken[]>((acc, token) => {
        const newAcc = [...acc];

        const foundIndex = findIndex(newAcc, { symbol: token.symbol });

        if (foundIndex !== -1) {
          if (!newAcc[foundIndex].versions.includes(token.version)) {
            newAcc[foundIndex].versions = uniq([...token.versions, ...newAcc[foundIndex].versions]);
            newAcc[foundIndex].tokens = uniq([...token.tokens, ...newAcc[foundIndex].tokens]);
            newAcc[foundIndex].chainIds = uniq([...token.chainIds, ...newAcc[foundIndex].chainIds]);
          }

          if (!newAcc[foundIndex].logoURI && token.logoURI) {
            newAcc[foundIndex].logoURI = token.logoURI;
          }
        } else {
          newAcc.push(token);
        }
        return newAcc;
      }, []);
    }

    return result;
  }, [tokens, groupByChain, groupByVersion, search, selectedTokens, showYieldTokens]);

  const itemData: RowData = React.useMemo(
    () => ({
      onClick: handleItemSelected,
      tokenList,
    }),
    [tokenList]
  );

  const onToggleGroupByChain = useCallback(() => {
    setGroupByChain((oldGroupByChain) => !oldGroupByChain);
    setSelectedTokens([]);
  }, []);
  const onToggleGroupByVersion = useCallback(() => {
    setGroupByVersion((oldGroupByVersion) => !oldGroupByVersion);
    setSelectedTokens([]);
  }, []);
  const onToggleShowYieldTokens = useCallback(() => {
    setShowYieldTokens((oldShowYieldTOkens) => !oldShowYieldTOkens);
    setSelectedTokens([]);
  }, []);

  return (
    <Modal open={!!isOpen} onClose={onClose} closeOnBackdrop maxWidth="sm" actions={[]} fullHeight keepMounted>
      <StyledOverlay>
        <IconButton
          aria-label="close"
          size="small"
          onClick={handleOnClose}
          style={{ position: 'absolute', top: '24px', right: '32px' }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Grid container spacing={1} direction="column" style={{ flexWrap: 'nowrap' }}>
          <StyledGrid item xs={12} customSpacing={12} style={{ flexBasis: 'auto' }}>
            <StyledFilledInput
              placeholder="Search your token by symbol, name or address"
              value={search}
              onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                setSearch(evt.currentTarget.value)
              }
              fullWidth
              inputRef={searchInputRef}
              disableUnderline
              endAdornment={
                <StyledEndAdormentContainer>
                  <Tooltip title="Paste address from clipboard" arrow placement="top">
                    <StyledPasteIcon onClick={onPasteAddress} />
                  </Tooltip>
                  <Search />
                </StyledEndAdormentContainer>
              }
              type="text"
              margin="none"
            />
          </StyledGrid>
          <StyledSwitchGrid item xs={12}>
            Group by chain
            <Switch checked={groupByChain} onChange={onToggleGroupByChain} name="groupByChain" color="primary" />
          </StyledSwitchGrid>
          <StyledSwitchGrid item xs={12}>
            Group by version
            <Switch checked={groupByVersion} onChange={onToggleGroupByVersion} name="groupByVersion" color="primary" />
          </StyledSwitchGrid>
          <StyledSwitchGrid item xs={12}>
            Show yield tokens
            <Switch
              checked={showYieldTokens}
              onChange={onToggleShowYieldTokens}
              name="showYieldTokens"
              color="primary"
            />
          </StyledSwitchGrid>
          <StyledGrid item xs={12} customSpacing={10} style={{ flexBasis: 'auto' }}>
            <StyledDialogTitle>
              <Typography variant="body1" fontWeight={600} fontSize="1.2rem">
                Token list
              </Typography>
            </StyledDialogTitle>
          </StyledGrid>
          <StyledGrid item xs={12} customSpacing={10} style={{ flexBasis: 'auto', flexWrap: 'wrap' }}>
            <Typography variant="body2">Selected tokens</Typography>
            {selectedTokens.map((token) => {
              const network = getChainByKey(token.chainId)!;

              const networkToken = toToken({
                address: network.wToken,
                chainId: network.chainId,
                logoURI: getGhTokenListLogoUrl(network.chainId, 'logo'),
              });

              return (
                <Chip
                  size="small"
                  label={`${token.symbol} on ${token.version}`}
                  onDelete={() => handleDeselect(token)}
                  icon={<ComposedTokenIcon isInChip tokenTop={token} tokenBottom={networkToken} />}
                />
              );
            })}
          </StyledGrid>
          <StyledGrid item xs={12} style={{ flexGrow: 1 }}>
            <AutoSizer>
              {({ height, width }) => (
                <StyledList
                  height={height}
                  itemCount={itemData.tokenList.length || 1}
                  itemSize={52}
                  width={width}
                  itemData={itemData}
                >
                  {Row}
                </StyledList>
              )}
            </AutoSizer>
          </StyledGrid>
          <StyledGrid item xs={12} customSpacing={10} style={{ flexBasis: 'auto' }}>
            <Button variant="contained" color="primary" onClick={handleConfirm} fullWidth>
              Confirm
            </Button>
          </StyledGrid>
        </Grid>
      </StyledOverlay>
    </Modal>
  );
};

export default TokenPicker;
