import { ThemeContext } from 'config/themeContext';
import { useContext } from 'react';

const useThemeMode = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('SomeChildComponent must be used within a ThemeProvider');
  }

  return context.theme;
};

export default useThemeMode;
