import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import find from 'lodash/find';
import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';
import { IOracleAggregator, OracleAggregator__factory } from '@mean-finance/oracles';
import filter from 'lodash/filter';
import Button from '@mui/material/Button';
import CenteredLoadingIndicator from 'common/centered-loading-indicator';
import { ORACLE_ADDRESS } from 'config/constants';
import { useQuery } from '@apollo/client';
import { GetPairResponseData, GetPairsResponseData, Token } from 'types';
import Card from '@mui/material/Card';
import OracleAggregatorAbi from 'abis/OracleAggregator.json';
import getPairs from 'graphql/getPairs.graphql';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import { buildEtherscanTransaction } from 'utils/etherscan';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useWalletClient, usePublicClient, useNetwork, useAccount } from 'wagmi';
import { getContract } from 'viem';

interface Pair {
  tokenA: Token;
  tokenB: Token;
  oracle?: string;
  id: string;
}

const ORACLES = {
  '0x5587d300d41e418b3f4dc7c273351748a116d78b': 'Chainlink',
  '0x8af2e0d92fbe4d7c44146089eb64a70d1cca4aea': 'Chainlink',
  '0xd741623299413d02256aac2101f8b30873fed1d2': 'Uniswap',
};

const OverrideOracleAdmin = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const { address: account } = useAccount();
  const chainId = chain?.id;
  const [isLoading, setIsLoading] = useState(true);
  const { loading: isLoadingPairs, data: pairsData } = useQuery<GetPairsResponseData>(getPairs);
  const [pairsOracle, setPairsOracle] = useState<Record<string, Pair>>({});
  const [selectedPairs, setSelectedPairs] = useState<Pair[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }
    if (!pairsData) {
      console.warn('pairs data not loaded');
      return;
    }

    const getOracleInfo = async () => {
      const oracleData = await Promise.all(
        pairsData.pairs.map(async (pair) => {
          const OracleAggregatorContract = getContract({
            address: ORACLE_ADDRESS[chainId || 10],
            abi: OracleAggregator__factory.abi,
            publicClient,
            walletClient,
          });

          let result: IOracleAggregator.OracleAssignmentStructOutput | null = null;
          try {
            result = (await OracleAggregatorContract.read.assignedOracle([
              pair.tokenA.address,
              pair.tokenB.address,
            ])) as IOracleAggregator.OracleAssignmentStructOutput;
          } catch (e) {
            setErrors((previousErrors) => [
              ...previousErrors,
              `Error getting oracleInUse for pair ${pair.tokenA.symbol || pair.tokenA.address}-${
                pair.tokenB.symbol || pair.tokenB.address
              }`,
            ]);
            console.error(
              `Error getting oracleInUse for pair ${pair.tokenA.symbol || pair.tokenA.address}-${
                pair.tokenB.symbol || pair.tokenB.address
              }`,
              e
            );
          }

          return {
            ...pick(pair, ['id', 'tokenA', 'tokenB']),
            oracle: result?.oracle.toLowerCase(),
          };
        })
      );

      setPairsOracle(keyBy(oracleData, 'id'));
      setIsLoading(false);
    };

    getOracleInfo();
  }, [walletClient, pairsData]);

  const onSelectAllPairs = () => {
    if (!pairsData) {
      return;
    }

    if (selectedPairs.length === pairsData.pairs.length) {
      setSelectedPairs([]);
    } else {
      setSelectedPairs(pairsData.pairs.map((pair) => ({ id: pair.id, tokenA: pair.tokenA, tokenB: pair.tokenB })));
    }
  };

  const pairResponseDataToPair = (pairData: GetPairResponseData): Pair => ({
    id: pairData.id,
    tokenA: pairData.tokenA,
    tokenB: pairData.tokenB,
  });

  const isPairSelected = (pairData: Pair) => {
    return !!find(selectedPairs, { tokenA: pairData.tokenA, tokenB: pairData.tokenB, id: pairData.id });
  };

  const handlePairClick = (pairData: Pair) => {
    const check = isPairSelected(pairData);
    if (!check) {
      setSelectedPairs((prevValue) => [...prevValue, pairData]);
    } else {
      setSelectedPairs(
        filter(selectedPairs, (pair) => pair.tokenA !== pairData.tokenA && pair.tokenB !== pairData.tokenB)
      );
    }
  };

  const onOverrideOracles = async () => {
    if (!walletClient) {
      return;
    }

    return Promise.all(
      selectedPairs.map(async (pair) => {
        const OracleAggreatorContract = getContract({
          address: ORACLE_ADDRESS[chainId || 10],
          abi: OracleAggregatorAbi.abi,
          publicClient,
          walletClient,
        });

        try {
          const contractResponse = await OracleAggreatorContract.write.overrideDefault([
            pair.tokenA.address,
            pair.tokenB.address,
          ]);

          window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
        } catch (e) {
          setErrors((previousErrors) => [
            ...previousErrors,
            `Error overriding default oracle for pair ${pair.tokenA.symbol || pair.tokenA.address}-${
              pair.tokenB.symbol || pair.tokenB.address
            }`,
          ]);
          console.error(
            `Error overriding default oracle for pair ${pair.tokenA.symbol || pair.tokenA.address}-${
              pair.tokenB.symbol || pair.tokenB.address
            }`,
            e
          );
        }
      })
    );
  };

  if (isLoading || isLoadingPairs) {
    return <CenteredLoadingIndicator />;
  }

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3">Pairs and Oracles</Typography>
      </Grid>
      {!!errors.length && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setErrors([])}>
            <AlertTitle>Error</AlertTitle>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selectedPairs.length > 0 && selectedPairs.length < Object.keys(pairsOracle).length}
                      checked={selectedPairs.length > 0 && selectedPairs.length === Object.keys(pairsOracle).length}
                      onChange={onSelectAllPairs}
                    />
                  </TableCell>
                  <TableCell>Token A</TableCell>
                  <TableCell>Token B</TableCell>
                  <TableCell>Oracle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(pairsOracle).map((pairKey, index) => (
                  <TableRow
                    hover
                    onClick={() => handlePairClick(pairsOracle[pairKey])}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    selected={isPairSelected(pairsOracle[pairKey])}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isPairSelected(pairsOracle[pairKey])} />
                    </TableCell>
                    <TableCell>{pairsOracle[pairKey].tokenA.symbol}</TableCell>
                    <TableCell>{pairsOracle[pairKey].tokenB.symbol}</TableCell>
                    <TableCell>
                      {/* {pairsOracle[pairKey].oracle} */}
                      {ORACLES[pairsOracle[pairKey].oracle as keyof typeof ORACLES]} ({pairsOracle[pairKey].oracle})
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Button variant="contained" onClick={onOverrideOracles} disabled={!selectedPairs.length}>
          Override oracle for selected pairs
        </Button>
      </Grid>
    </Grid>
  );
};
export default OverrideOracleAdmin;
