import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HUB_ADDRESS, SWAP_INTERVALS } from 'config/constants';
import HubAbi from 'abis/Hub.json';
import { SwapIntervalItem } from 'types';
import CenteredLoadingIndicator from 'common/centered-loading-indicator';
import { buildEtherscanTransaction } from 'utils/etherscan';
import { useNetwork, usePublicClient, useWalletClient } from 'wagmi';
import { getContract } from 'viem';

const SwapIntervalsAdmin = () => {
  const { chain } = useNetwork();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const chainId = chain?.id;
  const [approveValue, setApproveValue] = useState(0);
  const [approvedValuesToSend, setApprovedValuesToSend] = useState<number[]>([]);
  const [initalApproved, setInitialApproved] = useState(0);
  const [disableValue, setDisableValue] = useState(0);
  const [disabledValuesToSend, setDisabledValuesToSend] = useState<number[]>([]);
  const [initalDisabled, setInitialDisabled] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }

    const getIntervals = async () => {
      if (!walletClient) {
        return;
      }

      const HubContract = getContract({
        address: HUB_ADDRESS[chain?.id || 10],
        abi: HubAbi.abi,
        publicClient,
        walletClient,
      });

      const allowedIntervals = (await HubContract.read.allowedSwapIntervals()) as number;

      setApproveValue(allowedIntervals);
      setDisableValue(~allowedIntervals);
      setInitialApproved(allowedIntervals);
      setInitialDisabled(~allowedIntervals);
      setIsLoading(false);
    };

    getIntervals();
  }, [walletClient]);

  const handleApproveChange = (interval: SwapIntervalItem, shouldCheck: boolean) => {
    if (shouldCheck) {
      setApproveValue(approveValue | interval.key);
      setApprovedValuesToSend((prevValues) => [...prevValues, interval.value]);
    } else {
      setApproveValue(approveValue ^ interval.key);
      setApprovedValuesToSend((prevValues) => prevValues.filter((value) => value !== interval.value));
    }
  };

  const getApproveChecked = (value: number) => {
    return (approveValue & value) !== 0;
  };

  const getInitialApproveChecked = (value: number) => {
    return (initalApproved & value) !== 0;
  };

  const handleDisableChange = (interval: SwapIntervalItem, shouldCheck: boolean) => {
    if (shouldCheck) {
      setDisableValue(disableValue | interval.key);
      setDisabledValuesToSend((prevValues) => [...prevValues, interval.value]);
    } else {
      setDisableValue(disableValue ^ interval.key);
      setDisabledValuesToSend((prevValues) => prevValues.filter((value) => value !== interval.value));
    }
  };

  const getDisableChecked = (value: number) => {
    return (disableValue & value) !== 0;
  };

  const getInitialDisableChecked = (value: number) => {
    return (initalDisabled & value) !== 0;
  };

  const enableIntervals = async () => {
    if (!walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chain?.id || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await HubContract.write.addSwapIntervalsToAllowedList([
        approvedValuesToSend.map((value) => BigInt(value)),
      ]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const disableIntervals = async () => {
    if (!walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chain?.id || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await HubContract.write.removeSwapIntervalsFromAllowedList([
        disabledValuesToSend.map((value) => BigInt(value)),
      ]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    return <CenteredLoadingIndicator />;
  }

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">Enable swap intervals</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Initially enabled:</Typography>
        {SWAP_INTERVALS.filter((swapInterval) => getInitialApproveChecked(swapInterval.key)).map((swapInterval) => (
          <Typography variant="body1" key={swapInterval.key}>
            {swapInterval.description}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={12}>
        {SWAP_INTERVALS.map((swapInterval) => (
          <FormControlLabel
            key={swapInterval.key}
            control={
              <Checkbox
                onChange={(event) => handleApproveChange(swapInterval, event.target.checked)}
                checked={getApproveChecked(swapInterval.key)}
              />
            }
            label={swapInterval.description}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={enableIntervals}>
          Enable intervals
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Disable swap intervals</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Initially disabled:</Typography>
        {SWAP_INTERVALS.filter((swapInterval) => getInitialDisableChecked(swapInterval.key)).map((swapInterval) => (
          <Typography variant="body1" key={swapInterval.key}>
            {swapInterval.description}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={12}>
        {SWAP_INTERVALS.map((swapInterval) => (
          <FormControlLabel
            key={swapInterval.key}
            control={
              <Checkbox
                onChange={(event) => handleDisableChange(swapInterval, event.target.checked)}
                checked={getDisableChecked(swapInterval.key)}
              />
            }
            label={swapInterval.description}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={disableIntervals}>
          Disable intervals
        </Button>
      </Grid>
    </Grid>
  );
};
export default SwapIntervalsAdmin;
