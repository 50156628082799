import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import TimerIcon from '@mui/icons-material/Timer';
import { useNavigate } from 'react-router-dom';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
interface DrawerProps {
  toggleDrawer: () => void;
}

const Drawer = ({ toggleDrawer }: DrawerProps) => {
  const navigate = useNavigate();

  const handleListClick = (path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={() => toggleDrawer()} onKeyDown={() => toggleDrawer()}>
      <List>
        <ListItem key="SwapIntervals" button onClick={() => handleListClick('swapintervals')}>
          <ListItemIcon>
            <TimerIcon />
          </ListItemIcon>
          <ListItemText primary="Swap intervals" />
        </ListItem>
        <ListItem key="CreatePosition" button onClick={() => handleListClick('createposition')}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Create position" />
        </ListItem>
        <ListItem key="ModifyPosition" button onClick={() => handleListClick('modifyposition')}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Modify position" />
        </ListItem>
        <ListItem key="SwapsV2" button onClick={() => handleListClick('swap-v2')}>
          <ListItemIcon>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText primary="Swap v2" />
        </ListItem>
        <ListItem key="OverrideOracle" button onClick={() => handleListClick('override-oracle')}>
          <ListItemIcon>
            <TrackChangesIcon />
          </ListItemIcon>
          <ListItemText primary="Override oracle" />
        </ListItem>
        <ListItem key="TokenList" button onClick={() => handleListClick('token-list')}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Modify token list" />
        </ListItem>
        <ListItem key="removeallowance" button onClick={() => handleListClick('remove-allowance')}>
          <ListItemIcon>
            <RemoveModeratorIcon />
          </ListItemIcon>
          <ListItemText primary="Remove allowance" />
        </ListItem>
        <ListItem key="whitelistTokens" button onClick={() => handleListClick('whitelist-tokens')}>
          <ListItemIcon>
            <RemoveModeratorIcon />
          </ListItemIcon>
          <ListItemText primary="Whitelist tokens" />
        </ListItem>
        <ListItem key="checklistToken" button onClick={() => handleListClick('token-checklist')}>
          <ListItemIcon>
            <RemoveModeratorIcon />
          </ListItemIcon>
          <ListItemText primary="Token checklist" />
        </ListItem>
        <ListItem key="checklistToken" button onClick={() => handleListClick('volume-tracker')}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Volume Tracker" />
        </ListItem>
        <ListItem key="positionAnalytics" button onClick={() => handleListClick('position-analytics')}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Position analytics" />
        </ListItem>
        {/* <ListItem key="terminateMany" button onClick={() => handleListClick('terminate-many')}>
          <ListItemIcon>
            <RemoveModeratorIcon />
          </ListItemIcon>
          <ListItemText primary="Terminate many" />
        </ListItem> */}
      </List>
    </Box>
  );
};

export default Drawer;
