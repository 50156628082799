import gql from 'graphql-tag';
import { TokenType } from 'types';

export interface SwapDataBaseToken {
  address: string;
  decimals: number;
  symbol: string;
}
export interface SwapDataToken extends SwapDataBaseToken {
  type: TokenType;
  underlyingTokens: SwapDataBaseToken[];
}

export interface GetPositionsSwapsData {
  id: string;
  rate: string;
  rateUnderlying: string;
  swapped: string;
  swappedUnderlying: string;
  createdAtTimestamp: string;
  position: {
    from: SwapDataToken;
    to: SwapDataToken;
  };
}

export interface GetPositionsSwapsResponseData {
  swappedActions: GetPositionsSwapsData[];
}

const getPositionsSwaps = gql`
  query getPositionsSwaps($positions: [String]!, $first: Int, $lastId: String) {
    swappedActions(first: $first, where: { position_in: $positions, id_gt: $lastId }, subgraphError: "allow") {
      id
      rate
      rateUnderlying
      swapped
      swappedUnderlying
      createdAtTimestamp
      position {
        from {
          address: id
          decimals
          type
          symbol
          underlyingTokens {
            address: id
            decimals
          }
        }
        to {
          address: id
          decimals
          type
          symbol
          underlyingTokens {
            address: id
            symbol
            decimals
          }
        }
      }
    }
  }
`;

export default getPositionsSwaps;
