import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { buildEtherscanTransaction } from 'utils/etherscan';
import { Address, getContract } from 'viem';
import { useWalletClient, usePublicClient, useNetwork, erc20ABI } from 'wagmi';

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
`;

const RemoveAllowanceAdmin = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const [token, setToken] = useState<Address>('0x');
  const [contract, setContract] = useState<Address>('0x');
  const [allowanceAmount, setAllowanceAmount] = useState('');

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }
  }, [walletClient]);

  const onRemoveAllowance = async () => {
    if (!walletClient) {
      return;
    }

    try {
      const erc20 = getContract({
        address: token,
        abi: erc20ABI,
        publicClient,
        walletClient,
      });
      const contractResponse = await erc20.write.approve([contract, BigInt(allowanceAmount || '0')]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">Remove allowance for token</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                id="Token"
                label="Token"
                onChange={(event) => setToken(event.target.value as Address)}
                value={token}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                id="contract"
                label="Contract"
                onChange={(event) => setContract(event.target.value as Address)}
                value={contract}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                id="customAmount"
                label="Custom amount"
                onChange={(event) => setAllowanceAmount(event.target.value)}
                value={allowanceAmount}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={onRemoveAllowance}>
                Remove allowance
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};
export default RemoveAllowanceAdmin;
