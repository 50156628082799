import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import HubAbi from 'abis/Hub.json';
import { buildEtherscanTransaction } from 'utils/etherscan';
import { getContract } from 'viem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HUB_ADDRESS } from 'config/constants';
import { useWalletClient, usePublicClient, useNetwork, useAccount } from 'wagmi';

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
`;

const WhitelistAdmin = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const [tokens, setTokens] = useState('');
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }
  }, [walletClient]);

  const onSetTokens = async () => {
    if (!walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chainId || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    let tokensToSet = tokens.split(',');

    tokensToSet = tokensToSet.map((token) => token.trim());
    const enableds = tokensToSet.map(() => enabled);
    try {
      const contractResponse = await HubContract.write.setAllowedTokens([tokensToSet, enableds]);

      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">Set enabled for tokens</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="Token"
                label="Tokens separated by comma"
                onChange={(event) => setTokens(event.target.value)}
                value={tokens}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox onChange={(event) => setEnabled(event.target.checked)} checked={enabled} />}
                label="enable"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={onSetTokens}>
                Set whitelist
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};
export default WhitelistAdmin;
