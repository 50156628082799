import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import { HUB_ADDRESS, SWAP_INTERVALS } from 'config/constants';
import HubAbi from 'abis/Hub.json';
import CenteredLoadingIndicator from 'common/centered-loading-indicator';
import { buildEtherscanTransaction } from 'utils/etherscan';
import { erc20ABI, useAccount, useNetwork, usePublicClient, useWalletClient } from 'wagmi';
import { Address, formatUnits, getContract, maxUint256, parseUnits } from 'viem';

interface Token {
  name: string;
  symbol: string;
  address: Address;
  decimals: number;
}
interface Position {
  from: Token;
  to: Token;
  swapInterval: number;
  swapsExecuted: number;
  swapped: bigint;
  swapsLeft: number;
  remaining: bigint;
  rate: bigint;
  id: string;
}
const PositionModificationAdmin = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useNetwork();
  const { address: account } = useAccount();
  const chainId = chain?.id;
  const [positionId, setPositionId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState<Position | null>(null);
  const [recipientSwapped, setRecipientSwapped] = useState(account);
  const [recipientUnswapped, setRecipientUnswapped] = useState(account);
  const [fromAllowance, setFromAllowance] = useState(BigInt(0));
  const getPosition = async () => {
    if (!walletClient || !account) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chain?.id || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    setIsLoading(true);

    const fetchedPosition = (await HubContract.read.userPosition([positionId])) as any;

    const FromContract = getContract({
      address: fetchedPosition.from,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    const ToContract = getContract({
      address: fetchedPosition.to,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    const fromData = await Promise.all([
      FromContract.read.name(),
      FromContract.read.decimals(),
      FromContract.read.symbol(),
    ]);
    const toData = await Promise.all([ToContract.read.name(), ToContract.read.decimals(), ToContract.read.symbol()]);
    const fromAllowance = await FromContract.read.allowance([account, HUB_ADDRESS[chainId || 10]]);
    setFromAllowance(fromAllowance);
    setPosition({
      id: positionId,
      from: {
        name: fromData[0],
        decimals: fromData[1],
        symbol: fromData[2],
        address: fetchedPosition.from,
      },
      to: {
        name: toData[0],
        decimals: toData[1],
        symbol: toData[2],
        address: fetchedPosition.to,
      },
      swapInterval: fetchedPosition.swapInterval,
      swapsExecuted: fetchedPosition.swapsExecuted,
      swapped: fetchedPosition.swapped,
      swapsLeft: fetchedPosition.swapsLeft,
      remaining: fetchedPosition.remaining,
      rate: fetchedPosition.rate,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }
  }, [walletClient]);

  const modifyPosition = async () => {
    if (!position || !walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chainId || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    try {
      let contractResponse;
      const newAmount = position.rate * BigInt(position.swapsLeft);
      if (newAmount >= position.remaining) {
        contractResponse = await HubContract.write.increasePosition([
          positionId,
          newAmount - position.remaining,
          position.swapsLeft,
        ]);
      } else {
        contractResponse = await HubContract.write.reducePosition([
          positionId,
          position.remaining - newAmount,
          position.swapsLeft,
          recipientUnswapped,
        ]);
      }
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const withdrawPosition = async () => {
    if (!position || !walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chainId || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await HubContract.write.withdrawSwapped([positionId, recipientSwapped]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const terminatePosition = async () => {
    if (!position || !walletClient) {
      return;
    }

    const HubContract = getContract({
      address: HUB_ADDRESS[chainId || 10],
      abi: HubAbi.abi,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await HubContract.write.terminate([positionId, recipientUnswapped, recipientSwapped]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const approveToken = async () => {
    if (!position || !walletClient) {
      return;
    }

    const erc20 = getContract({
      address: position.from.address,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await erc20.write.approve([HUB_ADDRESS[chainId || 10], maxUint256]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const hasAllowedBefore = () => {
    if (!position) {
      return true;
    }

    const newAmount = position.rate * BigInt(position.swapsLeft);
    if (newAmount >= position.remaining) {
      return fromAllowance >= newAmount - position.remaining;
    } else {
      return true;
    }
  };

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">Position Id</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="positionId"
          label="Position Id"
          onChange={(event) => setPositionId(event.target.value)}
          value={positionId}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {isLoading && <CenteredLoadingIndicator />}
            {!isLoading && !position && (
              <Typography variant="h5">Set the position id and click on get position</Typography>
            )}
            {!isLoading && position && (
              <Grid container alignItems="center" justifyContent="center" spacing={1}>
                <Grid xs={12} item>
                  <Typography variant="h5">
                    From: {position.from.name} - {position.from.symbol} ({position.from.address})
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography variant="h5">
                    To: {position.to.name} - {position.to.symbol} ({position.to.address})
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography color="textSecondary" gutterBottom>
                    Swap interval:{' '}
                    {SWAP_INTERVALS.filter((swapInterval) => swapInterval.value === position?.swapInterval).map(
                      (interval) => interval.description
                    )}
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Typography color="textSecondary" gutterBottom>
                    Swaps executed: {position.swapsExecuted}
                  </Typography>
                </Grid>

                <Grid xs={12} item>
                  <Typography color="textSecondary" gutterBottom>
                    Swapped: {formatUnits(position.swapped, position.to.decimals)} {position.to.symbol}
                  </Typography>
                </Grid>

                <Grid xs={12} item>
                  <Typography color="textSecondary" gutterBottom>
                    Remaining: {formatUnits(position.remaining, position.from.decimals)} {position.from.symbol}
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    required
                    fullWidth
                    id="swapsLeft"
                    label="Swaps Left"
                    onChange={(event) =>
                      setPosition({
                        ...position,
                        swapsLeft: parseInt(event.target.value, 10) || 0,
                      })
                    }
                    value={position.swapsLeft}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    required
                    fullWidth
                    id="rate"
                    label="Rate"
                    onChange={(event) =>
                      setPosition({
                        ...position,
                        rate: parseUnits(event.target.value || '0', position.from.decimals),
                      })
                    }
                    value={formatUnits(position.rate, position.from.decimals)}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    required
                    fullWidth
                    id="recipientSwapped"
                    label="Swapped recipient"
                    onChange={(event) => setRecipientSwapped(event.target.value as Address)}
                    value={recipientSwapped}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    required
                    fullWidth
                    id="recipientUnswapped"
                    label="Unswapped recipient"
                    onChange={(event) => setRecipientUnswapped(event.target.value as Address)}
                    value={recipientUnswapped}
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      {!hasAllowedBefore() && position && (
        <Grid item xs={12}>
          <Alert severity="warning">You dont have enough allowance for the from token</Alert>
          <Button variant="contained" onClick={approveToken}>
            Approve {position.from.symbol}
          </Button>
        </Grid>
      )}
      <Grid item xs={3}>
        <Button variant="contained" onClick={getPosition}>
          Get position
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={withdrawPosition} disabled={!position || position.id !== positionId}>
          Withdraw from position
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={modifyPosition} disabled={!position || position.id !== positionId}>
          Modify rate/swaps
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={terminatePosition} disabled={!position || position.id !== positionId}>
          Terminate position
        </Button>
      </Grid>
    </Grid>
  );
};
export default PositionModificationAdmin;
