import gql from 'graphql-tag';

export interface GetPairsByTokenResponseData {
  pairs: {
    id: string;
  }[];
}

export const getPairsByTokenA = gql`
  query getPairsByToken($first: Int, $tokenA: [String], $lastId: String) {
    pairs(first: $first, where: { id_gt: $lastId, tokenA_in: $tokenA }, subgraphError: "allow") {
      id
    }
  }
`;

export const getPairsByTokenB = gql`
  query getPairsByToken($first: Int, $tokenB: [String], $lastId: String) {
    pairs(first: $first, where: { id_gt: $lastId, tokenB_in: $tokenB }, subgraphError: "allow") {
      id
    }
  }
`;
